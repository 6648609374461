import React, { FunctionComponent } from 'react'
import { GestureResponderEvent, Pressable, StyleSheet, Text } from 'react-native'
import Colors from '../constants/Colors'

type SwitchButtonProps = {
  text: string
  selected: boolean
  onPress: (event: GestureResponderEvent) => void
}

const SwitchButton: FunctionComponent<SwitchButtonProps> = (props: SwitchButtonProps) => {
  if (props.selected)
  {
	  return (
		<Pressable style={styles.SwitchButtonSelected} onPress={props.onPress}>
		  <Text style={styles.buttonTextSelected}>{props.text}</Text>
		</Pressable>
	  )
  }
  else
  {
	  return (
		<Pressable style={styles.SwitchButton} onPress={props.onPress}>
		  <Text style={styles.buttonText}>{props.text}</Text>
		</Pressable>
		  
	  )
  }
}

const styles = StyleSheet.create({
  buttonText: {
    color: '#000000',
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  SwitchButton: {
    backgroundColor: Colors.lightgrey,
    borderRadius: 4,
    padding: 10
  },
  buttonTextSelected: {
    color: '#ffffff',
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  SwitchButtonSelected: {
    backgroundColor: Colors.green,
    borderRadius: 4,
    padding: 10
  }
})

export default SwitchButton
