import React, { useState } from "react";
import { StyleSheet, View, Linking, Text } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Button, FormGroup, FormControl } from "react-bootstrap";
import Api from '../components/Api'
import LabeledNumberField from '../components/LabeledNumberField'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import ConditionalButton from '../components/ConditionalButton'
import DefaultButton from '../components/DefaultButton'
import Heading from '../components/Heading'
import Para from '../components/Para'
import AsyncStorage from '@react-native-async-storage/async-storage'

import styles from '../constants/Styles'
import Colors from '../constants/Colors'

export default function SetBaseline(props) {
  const [car, setCar] = useState("");
  const [cycle, setCycle] = useState("");
  const [walk, setWalk] = useState("");
  const [publicTransport, setPublicTransport] = useState("");
  const [an_error, setError] = useState(false);
  
 const updateCar = (text: string) => {
	 console.log(text)
	  setCar(text)
  }
  
 const updateCycle = (text: string) => {
	  setCycle(text)
  }
  
 const updateWalk = (text: string) => {
	  setWalk(text)
  }
  
 const updatePublicTransport = (text: string) => {
	  setPublicTransport(text)
  }
  function isNumeric(value) {
		return /^-?\d+$/.test(value);
	}
  
  const handleSubmit = (event) => {
    event.preventDefault();
	
	AsyncStorage.getItem('reguser').then((value) => {
    	const user = value

	const api = new Api();
	api.updateUserBaseline(user, car, cycle, walk, publicTransport, 'commute', function done(data) {
			if (isNumeric(car))
			{
				const cO2Saving = car * 0.221 * 2
				AsyncStorage.setItem('CO2Saving', cO2Saving.toString())
				AsyncStorage.setItem('carDistance', car.toString())
				
				const costSaving = car * 0.225 * 2
				AsyncStorage.setItem('costSaving', costSaving.toFixed(2).toString())
			}
			if (isNumeric(cycle))
			{
				const cycleCalories = cycle * 55 * 2
				AsyncStorage.setItem('cycleCalories', cycleCalories.toString())
			}
			if (isNumeric(walk))
			{
				const walkCalories = walk * 90 * 2
				AsyncStorage.setItem('walkCalories', walkCalories.toString())
				AsyncStorage.setItem('walkDistance', walk.toString())
			}
			props.onBaselineSet()
			
	}, function notFound() {
		// not found
		//this.state.error = "Error";
		setError(true)
		console.log('not found');
	});
	//props.onBaselineSet()
	})
	
  }
  
  const handleBack = () => {
	  props.GoBack();
  }

  const validateForm = () => {
	  //return true;
    return car.length > 0 && cycle.length > 0 && walk.length > 0 && publicTransport.length > 0;
  }
  

  return (
  		<KeyboardAwareScrollView style={styles.outerContainer}>
		  <View style={styles.innerContainer}>
			<NavigationBar logout={props.logout} goHome={props.goHome} user={props.user} ></NavigationBar>
		  </View>
		  <View style={styles.container, styles.greenStripe}>
			<View style={[styles.innerContainer, styles.container]}>
				<Heading fontsize={34} >Register</Heading>
			</View>
		  </View>
		  <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
				<VerticalSpace height={20} />
				<Para>This information will be used to calculate the benefits you will gain from cycling and walking.</Para>
				<Para>If you were travelling by the following, how many miles is your journey from home to work?  Please note, this is a single journey not a return trip.  If you are not sure you can use <Text style={styles.linkstyle} onPress={() => Linking.openURL('http://maps.google.co.uk/')}>Google Maps</Text> to help you.</Para>
						<LabeledNumberField placeholder="Required" label="Car" onChange={updateCar} value={car}/>
						<LabeledNumberField placeholder="Required" label="Cycle" onChange={updateCycle} value={cycle}/>
						<LabeledNumberField placeholder="Required" label="Walk" onChange={updateWalk} value={walk}/>
						<LabeledNumberField placeholder="Required" label="Public transport walk" onChange={updatePublicTransport} value={publicTransport}/>
					<VerticalSpace height={40} />
						{ an_error == false ? null :
							<Para>There is a problem. Please check your internet connection and try again.</Para>
						}
					<ConditionalButton onPress={handleSubmit} condition={validateForm} text="Submit"/>
					<VerticalSpace height={20} />
					<DefaultButton onPress={handleBack} text={'Back'}/>
				<VerticalSpace height={20} />
			</View>
		  </View>
		  <FooterBar />
		</KeyboardAwareScrollView>


  );
}
