import React, { FunctionComponent } from 'react'
import { Image, Linking, StyleSheet, TouchableOpacity, View, Text } from 'react-native'
import Styles from '../constants/Styles'
import Images from '../constants/Images'
import Para from '../components/Para'
import AsyncStorage from '@react-native-async-storage/async-storage'

type NavigationBarIconsProps = {
  navigation: any,
  name: any,
  logout: Function,
  goHome: Function,
  user: any,
}


const NavigationBarIcons: FunctionComponent<NavigationBarIconsProps> = ({ navigation, name, logout, goHome, user }) => {

  return (
    <View style={styles.navigationBarIcons}>
      <TouchableOpacity onPress={goHome}>
        <Image style={styles.navigationBarIconSmall} source={require('../assets/home-dark.png')}></Image>
      </TouchableOpacity>
      <TouchableOpacity onPress={goHome}><Image style={styles.navigationBarLogo} source={Images.logo} ></Image></TouchableOpacity>
		{name != null ? <Para style={styles.participantName}>Participant:<br />{name}</Para> : null}
			{user == null ? <Text style={styles.logInOut} onPress={() => logout()} text={'Log in'}>Log in</Text> : <Text style={styles.logInOut} onPress={() => logout()} text={'Log out'}>Log out</Text>}

    </View>
  )
}

const styles = StyleSheet.create({
  navigationBarIcons: {
    display: 'flex',
	maxWidth: 1000,
    flexDirection: 'row',
    justifyContent: 'space-between',
	paddingLeft: 30,
	paddingRight: 30,
  },
  navigationBarLogo: {
    height: 75,
    width: 189,
	marginTop: 3
  },
  participantName: {
	  float: 'right',
	  paddingTop: 10,
  },
  navigationBarIconSmall: {
	marginTop: 10,
    height: 30,
    width: 38
  },
  logInOut: {
	  paddingTop: 15,
	  fontWeight: 'bold'
  }
})

export default NavigationBarIcons
