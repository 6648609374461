import React, { useState } from "react";
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Button, FormGroup, FormControl } from "react-bootstrap";
import Api from '../components/Api'
import LabeledTextField from '../components/LabeledTextField'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import ConditionalButton from '../components/ConditionalButton'
import Heading from '../components/Heading'
import Para from '../components/Para'

import styles from '../constants/Styles'
import Colors from '../constants/Colors'

export default function Privacy(props) {
  
  const handleSubmit = (event) => {
    event.preventDefault();
  }
  
  return (
  		<KeyboardAwareScrollView style={styles.outerContainer}>
		  <View style={styles.innerContainer}>
			<NavigationBar logout={props.logout} goHome={props.goHome} user={props.user} ></NavigationBar>
		  </View>
		  <View style={styles.container, styles.greenStripe}>
			<View style={[styles.innerContainer, styles.container]}>
				<Heading fontsize={34} >Privacy Policy</Heading>
			</View>
		  </View>
		  <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
				<VerticalSpace height={20} />
<Para>This privacy policy sets out how eDriving Solutions Ltd (eds Ltd)  uses and protects any information that you give to eds Ltd.</Para>
<Para>eds Ltd is committed to ensuring that your privacy is protected.  Should we ask you to provide certain information by which you can be identified, then you can be assured that it will only be used in accordance with this privacy statement.</Para>

<Para bold={true}>What data do we collect?</Para>

<Para>We may collect the following information:</Para>
<Para>{'\u2B24'}{'\u00A0'}name and job title</Para>
<Para>{'\u2B24'}{'\u00A0'}company address</Para>
<Para>{'\u2B24'}{'\u00A0'}contact information including email address</Para>
<Para>{'\u2B24'}{'\u00A0'}demographic information such as postcode, preferences and interests</Para>
<Para>{'\u2B24'}{'\u00A0'}other information relevant to customer surveys and/or offers</Para>
<Para>{'\u2B24'}{'\u00A0'}your results relating to the tests, assessments and e-learning you complete.</Para>

<Para bold={true}>How do we collect your data?</Para>

<Para>You directly provide eds Ltd with most of the data we collect. We collect and process data when you:</Para>
<Para>{'\u2B24'}{'\u00A0'}Register on-line or place an order for any of our products or services.</Para>
<Para>{'\u2B24'}{'\u00A0'}Voluntarily provide information when registering interest in any of our products or services</Para>
<Para>{'\u2B24'}{'\u00A0'}Use or view our website via your browser’s cookies</Para>

<Para>eds Ltd may also receive your data indirectly from the following sources:</Para>
<Para>{'\u2B24'}{'\u00A0'}companies or organisations that eds Ltd are providing products or services on behalf of</Para>

<Para bold={true}>How will we use your data?</Para>

<Para>We collect your data so that we can:</Para>

<Para>{'\u2B24'}{'\u00A0'}keep Internal records.</Para>
<Para>{'\u2B24'}{'\u00A0'}Use the information to improve our products and services.</Para>
<Para>{'\u2B24'}{'\u00A0'}Periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided.</Para>
<Para>{'\u2B24'}{'\u00A0'}From time to time, contact you for market research purposes.</Para>


<Para bold={true}>How do we store your data?</Para>

<Para>We are committed to ensuring that your information is secure.  In order to prevent unauthorised access or disclosure we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect.</Para>
<Para>We will keep your data for five years after which time it will be deleted from our database.</Para>




<Para bold={true}>What are your data protection rights?</Para>

<Para>eds Ltd would like to make sure you are fully aware of all your data protection rights.</Para>
<Para>You are entitled to the following:</Para>

<Para>The right to access - You have the right to request eds Ltd for copies of your personal data. We may charge a fee for this.</Para>

<Para>The right to rectification - You have the right to request eds Ltd correct any information you believe is inaccurate or complete any information you believe is incomplete.</Para>


<Para>The right to erasure - You have the right to request that eds Ltd erase your personal data, under certain conditions.</Para>

<Para>The right to restrict processing - You have the right to request that eds Ltd restrict the processing of your personal data, under certain conditions.</Para>

<Para>The right to object to processing - You have the right to object to eds Ltd processing of your personal data, under certain conditions.</Para>

<Para>The right to data portability - You have the right to request that eds Ltd transfer the data that we have collected to another organisation or directly to you, under certain conditions.</Para>

<Para>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us by email: enquiries@edrivingsolutions.com</Para>
<Para>Or write to us :<br />
19 Northfield Road<br />
Doncaster<br />
DN5 8AY</Para>

<Para>We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so.</Para>


<Para bold={true}>How we use cookies</Para>

<Para>A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added, and the cookie helps analyse web traffic or lets you know when you visit a particular site.  Cookies allow web applications to respond to you as an individual.  The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.</Para>

<Para>We use traffic log cookies to identify which pages are being used.  This helps us analyse data about web page traffic and improve our website in order to tailor it to customer needs.  We only use this information for statistical analysis purposes and then the data is removed from the system.</Para>

<Para>Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not.  A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.</Para>

<Para>You can choose to accept or decline cookies.  Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.</Para>




<Para bold={true}>Links to other websites</Para>

<Para>Our website may contain links to other websites of interest.  However, once you have used these links to leave our site, you should note that we do not have any control over that other website.  Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement.  You should exercise caution and look at the privacy statement applicable to the website in question.</Para>

					<VerticalSpace height={40} />
				<VerticalSpace height={20} />
			</View>
		  </View>
		  <FooterBar />
		</KeyboardAwareScrollView>


  );
}
