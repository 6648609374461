import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import InputSpinner from "react-native-input-spinner";
import { TextInput } from 'react-native-gesture-handler'

import Para from './Para'

type LabeledNumberFieldProps = {
  label: string,
  onChange: (text: string) => void,
  placeholder?: string
  value: string
  password?: string
  visible?: true
}

const LabeledNumberField = ({ label, onChange, placeholder = '', value, password = false, visible = true}: LabeledTextFieldProps) => {
	if(visible == true)
	{
  return (
    <View>
      <Para>{label}</Para>
	  <TextInput value={value} keyboardType='numeric' placeholder={placeholder} style={styles.textInput} onChangeText={onChange}/>
    </View>
  )
	}
	else
	{
		return <Text></Text>;
	}
}

export default LabeledNumberField

const styles = StyleSheet.create({
  textInput: {
    borderWidth: 1,
    fontSize: 16,
    height: 55,
    paddingHorizontal: 4,
	backgroundColor: '#ffffff',
  }
})
