import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, Linking } from 'react-native'
import Colors from '../constants/Colors'
import NavigationBarIcons from '../components/NavigationBarIcons'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Styles from '../constants/Styles'
import AsyncStorage from '@react-native-async-storage/async-storage'
import Modal from "react-native-modal";
import DefaultButton from '../components/DefaultButton'
import Para from '../components/Para'
import VerticalSpace from '../components/VerticalSpace'


type FooterBarProps = {
  gotopage: Function
}

const FooterBar = ({ gotopage }: FooterBarProps) => {
const [ac_brand, setACBrand] = useState('true');
const [easter_egg_count, setEasterEggCount] = useState(0);
	const [isModalVisible, setIsModalVisible] = useState(false);

	useEffect(() => {
		AsyncStorage.getItem('ac_brand').then((value) => {
			setACBrand(value)
		})

	}, [])

	const handleModal = () => setIsModalVisible(() => !isModalVisible);

const easterEggClick = () => {
	setEasterEggCount(easter_egg_count + 1);
	if (easter_egg_count == 5)
	{
		if (ac_brand == 'true')
		{
			setACBrand('false')
			AsyncStorage.setItem('ac_brand', 'false', () => {
			})
		}
		else
		{
			setACBrand('true')
			AsyncStorage.setItem('ac_brand', 'true', () => {
			})
		}
		handleModal()
	}
}

  return (
    <View style={styles.navigationBar}>
		<View style={[styles.innerContainer, styles.container]}>
			<Text style={{color: Colors.darkgrey}} onPress={easterEggClick}>&copy; {new Date().getFullYear()} by eDriving Solutions Ltd.</Text>
			<Text style={{color: Colors.darkgrey, textDecorationLine: 'underline', cursor: 'pointer'}} onPress={() => Linking.openURL('http://aktiv8change.edrivingsolutions.com/privacy')}>Privacy Policy</Text>
					  <Modal isVisible={isModalVisible} >
						<View  style={Styles.modal}>
							<Para>The brand has been updated. Please refresh</Para>
							<VerticalSpace height={20} />
						  <DefaultButton text="Close" onPress={handleModal} />
						  </View>
					  </Modal>
		</View>
    </View>
  )
}

const styles = StyleSheet.create({
  navigationBar: {
    // backgroundColor: Colors.primaryPurple, // AP
    backgroundColor: Colors.lightgrey,
    color: Colors.darkgrey, // Shine
    paddingBottom: 40,
    paddingTop: 40,
	zIndex: -1,
  },
  container: {
    paddingHorizontal: Styles.standardPadding,
  },
  innerContainer: {
	maxWidth: 1000,
    alignSelf: 'center',
    width: '100%',
  },

})


export default FooterBar
