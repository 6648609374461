import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Linking } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Button, FormGroup, FormControl } from "react-bootstrap";
import Api from '../components/Api'
import LabeledTextField from '../components/LabeledTextField'
import LabeledDropDown from '../components/LabeledDropDown'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import alert from '../components/alert'
import DefaultButton from '../components/DefaultButton'
import Heading from '../components/Heading'
import Para from '../components/Para'
import Moment from 'moment';
import { Card, Tile } from 'react-native-elements';

import styles from '../constants/Styles'
import Colors from '../constants/Colors'

export default function HelpCentre(props) {
	const handleSubmit = () => {
		props.GoToScreen('summary')
	}
	const handleDelete = () => {
		alert(
		  'Delete Account',
		  'Are you sure you want to delete your account?', // <- this part is optional, you can pass an empty string
		  [
			{
			  text: "Cancel",
			  onPress: () => console.log("Cancel Pressed"),
			  style: "cancel"
			},
			{ text: "OK", onPress: () => deleteAccount() }		  
		  ],
		  {cancelable: true},
		);
	}
	
	const deleteAccount = () => {
		const api = new Api();
		api.deleteMe(props.user.id, function done() {
			props.confirmDelete()
				
		}, function notFound() {
		});
	}

  return (
  		<KeyboardAwareScrollView style={styles.outerContainer}>
		  <View style={styles.innerContainer}>
			<NavigationBar logout={props.logout} goHome={props.goHome} user={props.user} ></NavigationBar>
		  </View>
		  <View style={styles.container, styles.greenStripe}>
			<View style={[styles.innerContainer, styles.container]}>
				<Heading fontsize={34} >My Account</Heading>
			</View>
		  </View>
		  <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
			  <Para>
				  You have been a member of the scheme since {Moment(props.user.dateTimeAdded).format("DD MMM YYYY")}
			  </Para>
				<VerticalSpace height={20} />
				<DefaultButton onPress={handleDelete} text={"Delete my account"}/>
				<VerticalSpace height={20} />
				<DefaultButton onPress={handleSubmit} text={"Done"}/>
				<VerticalSpace height={20} />
			</View>
		  </View>
		  <FooterBar />
		</KeyboardAwareScrollView>


  );
}
