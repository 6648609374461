import React, { useState } from "react";
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Button, FormGroup, FormControl } from "react-bootstrap";
import Api from '../components/Api'
import LabeledTextField from '../components/LabeledTextField'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import ConditionalButton from '../components/ConditionalButton'
import Heading from '../components/Heading'
import Para from '../components/Para'

import styles from '../constants/Styles'
import Colors from '../constants/Colors'

export default function Terms(props) {
  
  const handleSubmit = (event) => {
    event.preventDefault();
	
	/*const api = new Api();
	api.authenticate(email, password, function done(data) {
			localStorage.setItem('user', JSON.stringify(email));
			props.loginSucceeded()
			
	}, function notFound() {
		// not found
		//this.state.error = "Error";
		console.log('not found');
	});*/
  }
  
  return (
  		<KeyboardAwareScrollView style={styles.outerContainer}>
		  <View style={styles.innerContainer}>
			<NavigationBar logout={props.logout} goHome={props.goHome} user={props.user} ></NavigationBar>
		  </View>
		  <View style={styles.container, styles.greenStripe}>
			<View style={[styles.innerContainer, styles.container]}>
				<Heading fontsize={34} >Frequently Asked Questions</Heading>
			</View>
		  </View>
		  <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
				<VerticalSpace height={20} />
				<Para bold={true}>What do I have to do?</Para>
				<Para>Once registered you will be asked to complete a very short questionnaire asking how you travelled to work that week.  We estimate this will take no longer than 30 seconds to complete.</Para>
				<Para bold={true}>What are the possible benefits of taking part?</Para>
				<Para>As well as helping us to evaluate the benefits of JustOneJourney, you will also be able to see how many calories you have used and how much CO2 you have saved.</Para>
				<Para bold={true}>What are the possible disadvantages and risks of taking part?</Para>
				<Para>None</Para>
				<Para bold={true}>Can I change my mind about participating?</Para>
				<Para>Yes, you can remove your data off the system at any time.  You do this by going to…. and selecting ‘Remove my data’.  This will delete your account and remove your data from the system.  You can only do this up to the 1 Aug 2023, at which point the system will be shut down and your data will be depersonalised.</Para>
				<Para bold={true}>Will my taking part in this project be kept confidential?</Para>
				<Para>Yes, any information you provide will be stored safely and kept in line with the General Data Protection Regulations (GDPR)</Para>
				<Para bold={true}>What type of information will be sought from me and why is the collection of this information needed?</Para>
				<Para>We will ask for the following data:
				<ul>
					<li>Name – this will allow us to match your data on the system</li>
					<li>Company name – this will allow us to assess the benefits of JustOneJourney by organisation</li>
					<li>Email address – this will allow us to send you reminders to complete the system</li>
					<li>Mobile phone number – this will allow us to send you SMS reminders, if you elect for this option</li>
					<li>Current Age – this will allow us to assess the benefits of JustOneJourney by age group</li>
					<li>Gender - this will allow us to assess the benefits of JustOneJourney by gender</li>
					<li>Password – this is required so that only you can access your data through your interface.</li>
				</ul>
				</Para>
				<Para bold={true}>What will happen to the results of the research project?</Para>
				<Para>Results from the project will be published.  You will not be identified in any report or publication.</Para>
				<Para bold={true}>Who is organising and funding the research?</Para>
				<Para>The project is being completed by Warwickshire County Council with support from eDriving Solutions who have designed and are operating this system as part of the evaluation.  If you have any questions about this App please contact <a href='mailto:neil.beeson@nvc-limited.com'>neil.beeson@nvc-limited.com</a>, if you have any questions about JustOneJourney please contact: <a href='mailto:katecastle@warwickshire.gov.uk'>katecastle@warwickshire.gov.uk</a> </Para>
				<VerticalSpace height={20} />
			</View>
		  </View>
		  <FooterBar />
		</KeyboardAwareScrollView>


  );
}
