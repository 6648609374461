import React, { useState, useEffect } from "react";
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Button, FormGroup, FormControl } from "react-bootstrap";
import Api from '../components/Api'
import LabeledTextField from '../components/LabeledTextField'
import LabeledDropDown from '../components/LabeledDropDown'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import ConditionalButton from '../components/ConditionalButton'
import DefaultButton from '../components/DefaultButton'
import SwitchButton from '../components/SwitchButton'
import Heading from '../components/Heading'
import Para from '../components/Para'
import AsyncStorage from '@react-native-async-storage/async-storage'

import styles from '../constants/Styles'
import Colors from '../constants/Colors'

export default function SetPlanLunch(props) {
  const [monday, setMonday] = useState(false);
  const [tuesday, setTuesday] = useState("");
  const [wednesday, setWednesday] = useState("");
  const [thursday, setThursday] = useState("");
  const [friday, setFriday] = useState("");
  const [saturday, setSaturday] = useState("");
  const [sunday, setSunday] = useState("");
  
 const toggleMonday = () => {
	  setMonday(!monday)
  }
  
 const toggleTuesday = () => {
	  setTuesday(!tuesday)
  }
  
 const toggleWednesday = () => {
	  setWednesday(!wednesday)
  }
  
 const toggleThursday = () => {
	  setThursday(!thursday)
  }
  
 const toggleFriday = () => {
	  setFriday(!friday)
  }
  
 const toggleSaturday = () => {
	  setSaturday(!saturday)
  }
  
 const toggleSunday = () => {
	  setSunday(!sunday)
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();
	AsyncStorage.getItem('reguser').then((value) => {
    	const user = value
	
		var daysText = ''
		if (monday)
		{
			daysText += "Monday, ";
		}
		if (tuesday)
		{
			daysText += "Tuesday, ";
		}
		if (wednesday)
		{
			daysText += "Wednesday, ";
		}
		if (thursday)
		{
			daysText += "Thursday, ";
		}
		if (friday)
		{
			daysText += "Friday, ";
		}
		if (saturday)
		{
			daysText += "Saturday, ";
		}
		if (sunday)
		{
			daysText += "Sunday";
		}
		AsyncStorage.setItem('walkDaysLunch', daysText)

		const api = new Api();
		api.submitPlan(user, monday, tuesday, wednesday, thursday, friday, saturday, sunday, function done(data) {
				props.onPlanSet()
				
		}, function notFound() {
			// not found
			//this.state.error = "Error";
			console.log('not found');
		});
	})
  }

  const handleBack = () => {
	  props.GoBack();
  }
  
  const validateForm = () => {
    return true;
  }
  

  return (
  		<KeyboardAwareScrollView style={styles.outerContainer}>
		  <View style={styles.innerContainer}>
			<NavigationBar logout={props.logout} goHome={props.goHome} user={props.user} ></NavigationBar>
		  </View>
		  <View style={styles.container, styles.greenStripe}>
			<View style={[styles.innerContainer, styles.container]}>
				<Heading fontsize={34} >Register</Heading>
			</View>
		  </View>
		  <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
				<VerticalSpace height={20} />
					<Para>Finally, select the days when you feel most able to walk to lunch.  You can select as many days as you like, this is just to help you to think through what days you could consider walking.</Para>
						<SwitchButton selected={monday} onPress={toggleMonday} text="Monday"/>
						<VerticalSpace height={20} />
						<SwitchButton selected={tuesday} onPress={toggleTuesday} text="Tuesday"/>
						<VerticalSpace height={20} />
						<SwitchButton selected={wednesday} onPress={toggleWednesday} text="Wednesday"/>
						<VerticalSpace height={20} />
						<SwitchButton selected={thursday} onPress={toggleThursday} text="Thursday"/>
						<VerticalSpace height={20} />
						<SwitchButton selected={friday} onPress={toggleFriday} text="Friday"/>
						<VerticalSpace height={20} />
						<SwitchButton selected={saturday} onPress={toggleSaturday} text="Saturday"/>
						<VerticalSpace height={20} />
						<SwitchButton selected={sunday} onPress={toggleSunday} text="Sunday"/>
						<VerticalSpace height={20} />
					<VerticalSpace height={40} />
					<ConditionalButton onPress={handleSubmit} condition={validateForm} text="Submit"/>
					<VerticalSpace height={20} />
					<DefaultButton onPress={handleBack} text={'Back'}/>
				<VerticalSpace height={20} />
			</View>
		  </View>
		  <FooterBar />
		</KeyboardAwareScrollView>


  );
}

