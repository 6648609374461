import React, { FunctionComponent } from 'react'
import { GestureResponderEvent, Pressable, StyleSheet, Text, View } from 'react-native'
import Colors from '../constants/Colors'
import Styles from '../constants/Styles'

type FloatingDefaultButtonProps = {
  text: string
  onPress: (event: GestureResponderEvent) => void
}

const FloatingDefaultButton: FunctionComponent<FloatingDefaultButtonProps> = (props: FloatingDefaultButtonProps) => {
  return (
	<Pressable style={styles.FloatingDefaultButton} onPress={props.onPress}>
	  <Text style={styles.buttonText}>{props.text}</Text>
	</Pressable>
  )
}

const styles = StyleSheet.create({
  buttonText: {
    color: '#ffffff',
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  FloatingDefaultButton: {
    backgroundColor: Colors.green,
    borderRadius: 4,
    padding: 10,
	alignSelf:'center',
	bottom: 10,
	marginLeft: 30,
	marginRight: 30,
	marginTop: 10,
	width: '85%',
	paddingHorizontal: 15,
	marginHorizontal: 15,
  }
})

export default FloatingDefaultButton
