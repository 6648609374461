import React from 'react'
import { StyleSheet, View } from 'react-native'
import Colors from '../constants/Colors'
import NavigationBarIcons from '../components/NavigationBarIcons'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import TabOneScreen from '../screens/Welcome';
import TabTwoScreen from '../screens/Welcome';


type NavigationBarProps = {
  navigation: any
  name: any
  logout: Function
  goHome: Function
  user: any
}

const NavigationBar = ({ navigation, name, logout, goHome, user }: NavigationBarProps) => {
  return (
    <View style={styles.navigationBar}>
      <NavigationBarIcons navigation={navigation} name={name} logout={logout} goHome={goHome} user={user}></NavigationBarIcons>
    </View>
  )
}

const styles = StyleSheet.create({
  navigationBar: {
    color: Colors.dark,
    paddingBottom: 15,
    paddingTop: 40
  }
})

const BottomTab = createBottomTabNavigator<RootTabParamList>();


export default NavigationBar
