import React, { useState } from "react";
import { StyleSheet, View, Text, Linking, Dimensions } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Button, FormGroup, FormControl } from "react-bootstrap";
import Api from '../components/Api'
import LabeledTextField from '../components/LabeledTextField'
import LabeledTextFieldWithTooltip from '../components/LabeledTextFieldWithTooltip'
import LabeledDropDown from '../components/LabeledDropDown'
import LabeledDropDownWithTooltip from '../components/LabeledDropDownWithTooltip'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import ConditionalButton from '../components/ConditionalButton'
import DefaultButton from '../components/DefaultButton'
import Heading from '../components/Heading'
import Para from '../components/Para'
import AsyncStorage from '@react-native-async-storage/async-storage'

import styles from '../constants/Styles'
import Colors from '../constants/Colors'

import { BarPasswordStrengthDisplay } from 'react-native-password-strength-meter';



export default function Register(props) {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [mobile, setMobile] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [submitText, setSubmitText] = useState("Submit");
  const [bar_width, setBarWidth] = useState(null);
  const [an_error, setError] = useState(false);
  AsyncStorage.removeItem('user');
  AsyncStorage.removeItem('reguser');
  
 const updateEmail = (text: string) => {
	  setEmail(text)
  }
  
 const updateFirstName = (text: string) => {
	  setFirstName(text)
  }
  
 const updateLastName = (text: string) => {
	  setLastName(text)
  }
  
 const updateCompanyName = (text: string) => {
	  setCompanyName(text == null ? '' : text)
  }
  
 const updateConfirmEmail = (text: string) => {
	  setConfirmEmail(text)
  }
  
 const updateMobile = (text: string) => {
	  setMobile(text)
  }
  
 const updateAge = (text: string) => {
	  setAge(text)
  }
  
 const updateGender = (text: string) => {
	  setGender(text)
  }
  
 const updatePassword = (text: string) => {
	  setPassword(text)

  }
  
 const updateConfirmPassword = (text: string) => {
	  setConfirmPassword(text)
  }

  
  const validateForm = () => {
    return email.length > 0 && ValidateEmail(email) && password.length > 7 && firstName.length > 0 && lastName.length > 0 && companyName.length > 0 && confirmPassword == password && confirmEmail == email && submitting == false;
  }
  
  function ValidateEmail(mail) 
	{
	 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
	  {
		return (true)
	  }
		return (false)
	}

  const handleSubmit = (event) => {
    event.preventDefault();
	setSubmitting(true);
	setSubmitText("Please wait");
	
	const api = new Api();
	api.addNewUser(firstName, lastName, email, companyName, age, gender, mobile, password, function done(data) {
			AsyncStorage.setItem('reguser', JSON.stringify(data.id))
			props.onRegistered()
			
	}, function notFound() {
		// not found
		//this.state.error = "Error";
		setSubmitting(false);
		setError(true);
		setSubmitText("Submit");
		console.log('not found');
	});
	//props.onRegistered()
  }
  
  const handleBack = () => {
	  props.GoBack();
  }
  
  const companyItems = [
                { index: 1, label: 'Warwickshire CC', value: 'Warwickshire CC' },
                { index: 2, label: 'New View', value: 'New View' },
            ]
	const onLayout=(event)=> {
		const {x, y, height, width} = event.nativeEvent.layout;
		setBarWidth(width)
	}

  return (
  		<KeyboardAwareScrollView style={styles.outerContainer}>
		  <View style={styles.innerContainer}>
			<NavigationBar logout={props.logout} goHome={props.goHome} user={props.user} ></NavigationBar>
		  </View>
		  <View style={styles.container, styles.greenStripe}>
			<View style={[styles.innerContainer, styles.container]}>
				<Heading fontsize={34} >Register</Heading>
			</View>
		  </View>
		  <View style={styles.container} onLayout={onLayout}>
			<View style={[styles.innerContainer, styles.container]}>
				<VerticalSpace height={20} />
					<Para>Before we can help you to travel more actively we need to collect some information about you.  You can visit our privacy policy by clicking <Text style={styles.linkstyle} onPress={() => Linking.openURL('http://aktiv8change.edrivingsolutions.com/privacy')}>here</Text>.</Para>
						<LabeledTextFieldWithTooltip tooltipid={"tooltip1"} tooltip="This will allow us to match your data on the system" placeholder="Required" label="First name" onChange={updateFirstName} value={firstName} isValid={firstName != ''} />

						<LabeledTextFieldWithTooltip tooltipid={"tooltip2"} tooltip="This will allow us to match your data on the system" placeholder="Required" label="Last name" onChange={updateLastName} value={lastName} isValid={lastName != ''}/>

						<LabeledDropDownWithTooltip tooltipid={"tooltip3"} items={companyItems} tooltip="This will allow us assess the benefits of JustOneJourney by organisation" placeholder="Required" label="Company name" onChange={updateCompanyName} value={companyName} isValid={companyName != '' && companyName != null}/>

						<LabeledTextFieldWithTooltip tooltipid={"tooltip4"} tooltip="This will allow us to send you reminders to complete the system " placeholder="Required" label="Email address" onChange={updateEmail} value={email} isValid={ValidateEmail(email)}/>

						<LabeledTextFieldWithTooltip tooltipid={"tooltip5"} placeholder="Required" tooltip="This is to confirm you entered your email address correctly" label="Confirm email address" onChange={updateConfirmEmail} value={confirmEmail} isValid={confirmEmail == email}/>

						<LabeledTextFieldWithTooltip tooltipid={"tooltip6"} tooltip="This will allow us to send you SMS reminders, if you elect for this option" label="Mobile number" onChange={updateMobile} value={mobile}/>

						<LabeledTextFieldWithTooltip tooltipid={"tooltip7"} tooltip="This will allow us to assess the benefits of JustOneJourney by age group" label="Current age" onChange={updateAge} value={age}/>

						<LabeledTextFieldWithTooltip tooltipid={"tooltip8"} tooltip="This will allow us to assess the benefits of JustOneJourney by gender" label="Gender?" onChange={updateGender} value={gender}/>

						<LabeledTextFieldWithTooltip tooltipid={"tooltip9"} tooltip="Your password must contain at least 8 characters, including at least one of each of the following: lower case letter, upper case letter, number, symbol (e.g. &*?). This is required so that only you can access your data through your interface." placeholder="Required" password={true} label="Password" onChange={updatePassword} value={password} type="password" isValid={password != ''}/>
							{bar_width == null ? null : <View style={styles.passwordStrength}><BarPasswordStrengthDisplay style={styles.passwordStrength} password={password} /></View>}
						
						<LabeledTextField placeholder="Required" password={true} label="Confirm password" onChange={updateConfirmPassword} value={confirmPassword} type="confirmPassword" isValid={password == confirmPassword}/>

					<VerticalSpace height={20} />
					<Para>By clicking the Submit button I agree to the scheme <Text style={styles.linkstyle} onPress={() => Linking.openURL('http://aktiv8change.edrivingsolutions.com/terms')}>Terms and Conditions</Text></Para>
						{ an_error == false ? null :
							<Para>There is a problem. Please check your internet connection and try again.</Para>
						}
					<VerticalSpace height={40} />
					<ConditionalButton onPress={handleSubmit} condition={validateForm} text={submitText}/>
					<VerticalSpace height={20} />
					<DefaultButton onPress={handleBack} text={'Back'}/>
				<VerticalSpace height={20} />
			</View>
		  </View>
		  <FooterBar />
		</KeyboardAwareScrollView>


  );
}

