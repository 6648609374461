import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Button, FormGroup, FormControl } from "react-bootstrap";
import Api from '../components/Api'
import LabeledTextField from '../components/LabeledTextField'
import LabeledDropDown from '../components/LabeledDropDown'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import ConditionalButton from '../components/ConditionalButton'
import DefaultButton from '../components/DefaultButton'
import Heading from '../components/Heading'
import Para from '../components/Para'
import Modal from "react-native-modal";
import AsyncStorage from '@react-native-async-storage/async-storage'

import styles from '../constants/Styles'
import Colors from '../constants/Colors'

export default function SetTarget(props) {
  const [target, setTarget] = useState("");
	const [costSaving, SetCostSaving] = useState(null)
	const [cO2Saving, SetcO2Saving] = useState(null)
	const [walkCalories, SetWalkCalories] = useState(null)
	const [carDistance, SetCarDistance] = useState(null)
	const [walkDistance, SetWalkDistance] = useState(null)
	const [isModalVisible, setIsModalVisible] = useState(false);

	useEffect(() => {
	AsyncStorage.getItem('carDistance').then((value) => {
		SetCarDistance(value)
	})

	AsyncStorage.getItem('walkDistance').then((value) => {
		SetWalkDistance(value)
	})
	}, [])
	
	const handleModal = () => {
		console.log(isModalVisible)
		setIsModalVisible(() => !isModalVisible);
	}

 const updateTarget = (text: string) => {
	setTarget(text == null ? '' : text)
	text = (text == null ? 0 : text)
	  
	if (isNumeric(carDistance) && isNumeric(walkDistance))
	{
		SetcO2Saving((carDistance * 0.221 * 2 * parseInt(text)).toFixed(2) * 47)
		
		SetCostSaving((carDistance * 0.225 * 2 * parseInt(text)).toFixed(2) * 47)

		SetWalkCalories((carDistance * 90 * 2 * parseInt(text)).toFixed(0) * 47)
		
		const cO2Saving = (carDistance * 0.221 * 2 * parseInt(text) * 47).toFixed(2)
		AsyncStorage.setItem('CO2Saving', cO2Saving)
		
		const costSaving = (carDistance * 0.225 * 2 * parseInt(text) * 47)
		AsyncStorage.setItem('costSaving', costSaving.toFixed(2))

		const walkCalories = (walkDistance * 90 * 2 * parseInt(text) * 47)
		AsyncStorage.setItem('walkCalories', walkCalories.toString())

		AsyncStorage.setItem('walkNumDays', text)
	}
  }

  function isNumeric(value) {
		return /^-?\d+$/.test(value);
	}
  const targetItems = [
                { label: '0', value: '0' },
                { label: '1', value: '1' },
                { label: '2', value: '2' },
                { label: '3', value: '3' },
                { label: '4', value: '4' },
                { label: '5', value: '5' },
                { label: '6', value: '6' },
                { label: '7', value: '7' },
            ]
  
  const handleSubmit = (event) => {
    event.preventDefault();
	AsyncStorage.getItem('reguser').then((value) => {
    	const user = value

		const api = new Api();
		api.submitTarget(user, target, 'commute', function done(data) {
				props.onTargetSet()
				
		}, function notFound() {
			// not found
			//this.state.error = "Error";
			console.log('not found');
		});
	})
  }
  
  const handleBack = () => {
	  props.GoBack();
  }

  const validateForm = () => {
    return target.length > 0;
  }
  

  return (
  		<KeyboardAwareScrollView style={styles.outerContainer}>
		  <View style={styles.innerContainer}>
			<NavigationBar logout={props.logout} goHome={props.goHome} user={props.user} ></NavigationBar>
		  </View>
		  <View style={styles.container, styles.greenStripe}>
			<View style={[styles.innerContainer, styles.container]}>
				<Heading fontsize={34} >Register</Heading>
			</View>
		  </View>
		  <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
				<VerticalSpace height={20} />
					<Para>Small, simple changes really matter!  Here you can see the benefits of walking to work. The more days you commit to, the more you will gain, but be realistic.</Para>
						<LabeledDropDown items={targetItems} placeholder="Required" label="Please select the number of days you aim to walk to work, to see the benefits." onChange={updateTarget} value={target} isValid={target.length > 0} />
					  {target.length == 0 ? <Text></Text> :
					  <View>
							<VerticalSpace height={20} />
							<Para>Based on the number of days selected your benefits over a year would be:</Para>
							<Para>Financial saving = <Text fontWeight={'bold'}>&pound;{parseFloat(costSaving).toFixed(2)}</Text></Para>
							<Para>CO2 saving = <Text fontWeight={'bold'}>{cO2Saving} kg</Text></Para>
							<Para>Calories burnt = <Text fontWeight={'bold'}>{walkCalories}</Text></Para>
							<Para>If you want more benefits, try selecting more days of the week</Para>
							<Para>Click <Text onPress={handleModal} style={styles.linkstyle} underline={'underline'} >here</Text> to see how we calculated these figures.</Para>
							
					  </View>
					  }
					<VerticalSpace height={40} />
					  <Modal isVisible={isModalVisible} >
						<View  style={styles.modal}>
							<Para>These are estimates based on typical values, and assuming you travel to work 47 weeks per year. CO2 produced is dependent on: </Para>
							<Text>{'\u2022'} Type of vehicle</Text>
							<Text>{'\u2022'} Driving style</Text>
							<Text>{'\u2022'} Journey type</Text>
							<Para>Calories burned is dependent on:</Para>
							<Text>{'\u2022'} Gender</Text>
							<Text>{'\u2022'} Weight</Text>
							<Text>{'\u2022'} Pace</Text>
							<Para>Fuel costs are on average 22.5p per mile, but vary dependent upon vehicle, engine type (diesel/petrol) and style of driving.
							</Para>
							<VerticalSpace height={20} />
						  <DefaultButton text="Close" onPress={handleModal} />
						  </View>
					  </Modal>
					<ConditionalButton onPress={handleSubmit} condition={validateForm} text="Set my personal goal"/>
					<VerticalSpace height={20} />
					<DefaultButton onPress={handleBack} text={'Back'}/>
				<VerticalSpace height={20} />
			</View>
		  </View>
		  <FooterBar />
		</KeyboardAwareScrollView>


  );
}


