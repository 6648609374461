import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, FlatList, ImageBackground, Image } from 'react-native'
import Colors from '../constants/Colors'
import NavigationBarIcons from '../components/NavigationBarIcons'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Styles from '../constants/Styles'
import Moment from 'moment';
import ConditionalButton from '../components/ConditionalButton'
import VerticalSpace from '../components/VerticalSpace'

type TableProps = {
  columns: any
  data: any
  hideSearch: any
  pages: any
  handleEdit: Function
}



export default function Table ({ columns, data, hideSearch, pages, handleEdit }: TableProps) {
	const [currentPage, setCurrentPage] = useState(0);
	const [displayData, setDisplayData] = useState(null);
	
   const localcolumns = React.useMemo(
     () => [
		{
			Header: "Day",
		},
     ],
     []
   )

   	useEffect(() => {
		setDisplayData(getData)
		console.log(displayData)
	}, [])

   const getData = (page) => {
	   const retVal = data.slice(page, page + 1)
	   console.log(retVal)
		return retVal
   }
   
   const loadNextPage = () => {
	   const newPage = currentPage + 1
	   setCurrentPage(newPage)
	   setDisplayData(getData(newPage))
   }
   
   const loadPreviousPage = () => {
	   const newPage = currentPage - 1
	   setCurrentPage(newPage)
	   setDisplayData(getData(newPage))
   }
   
   const endOfDays = () => {
	   return currentPage < data.length
   }

   const startOfDays = () => {
	   return currentPage > 0
   }
   
	return (
		<>
			<FlatList data={localcolumns} numColumns={1} renderItem={({item: the_item}) => <View style={styles.tableHeader}><Text style={styles.tableColumnDate}>{the_item.Header}</Text><Text style={styles.tableColumn}>Commutes</Text><Text style={styles.tableColumn}>Lunch</Text></View>} />
			<FlatList data={displayData} numColumns={1} renderItem={({item: the_item}) => 
			<View>
				{Moment(the_item.week_beginning).add(6, "days") > Moment(new Date()) ? null :
					<View style={styles.tableRow}>
						<Text style={styles.tableCellDate}>
						  {Moment(the_item.week_beginning).add(6, "days").format("ddd DD MMM")}
						</Text>
						<Text style={styles.tableCell}>
							{the_item.sunday == '' || the_item.sunday == null ? <Text style={{textAlign: 'left', backgroundColor: '#FFA500'}} onPress={e => handleEdit(the_item, "sunday", "commute")}>Edit</Text> : <Text style={{textAlign: 'left'}} onPress={e => handleEdit(the_item, "sunday", "commute")}>{the_item.sunday.map(({ id, travelTypeId, travelDistance }) => (<Text key={id} style={{padding:5}}> <Image source={travelTypeId == 1 ? require('../assets/car-icon.png') : travelTypeId == 2 ? require('../assets/cycle-icon.png') : travelTypeId == 3 ? require('../assets/walk-icon.png') : require('../assets/bus-icon.png')} style={styles.imageStyle} ></Image><Text style={styles.travelDistance}>{travelDistance}</Text> </Text>))}</Text>}
						</Text>
						<Text style={styles.tableCell}>
							{the_item.sunday_lunch == '' || the_item.sunday_lunch == null ? <Text style={{textAlign: 'left', backgroundColor: '#FFA500'}} onPress={e => handleEdit(the_item, "sunday lunch", "lunch")}>Edit</Text> : <Text style={{textAlign: 'left'}} onPress={e => handleEdit(the_item, "sunday lunch", "lunch")}>{the_item.sunday_lunch.map(({ id, travelTypeId, travelDistance }) => (<Text key={id} style={{padding:5}}> <Image source={travelTypeId == 1 ? require('../assets/car-icon.png') : travelTypeId == 2 ? require('../assets/cycle-icon.png') : travelTypeId == 3 ? require('../assets/walk-icon.png') : require('../assets/bus-icon.png')} style={styles.imageStyle} ></Image><Text style={styles.travelDistance}>{travelDistance}</Text> </Text>))}</Text>}
						</Text>
					</View>
				}
				{Moment(the_item.week_beginning).add(5, "days") > Moment(new Date()) ? null :
					<View style={styles.tableRow}>
						<Text style={styles.tableCellDate}>
						  {Moment(the_item.week_beginning).add(5, "days").format("ddd DD MMM")}
						</Text>
						<Text style={styles.tableCell}>
							{the_item.saturday == '' || the_item.saturday == null ? <Text style={{textAlign: 'left', backgroundColor: '#FFA500'}} onPress={e => handleEdit(the_item, "saturday", "commute")}>Edit</Text> : <Text style={{textAlign: 'left'}} onPress={e => handleEdit(the_item, "saturday", "commute")}>{the_item.saturday.map(({ id, travelTypeId, travelDistance }) => (<Text key={id} style={{padding:5}}> <Image source={travelTypeId == 1 ? require('../assets/car-icon.png') : travelTypeId == 2 ? require('../assets/cycle-icon.png') : travelTypeId == 3 ? require('../assets/walk-icon.png') : require('../assets/bus-icon.png')} style={styles.imageStyle} ></Image><Text style={styles.travelDistance}>{travelDistance}</Text> </Text>))}</Text>}
						</Text>
						<Text style={styles.tableCell}>
							{the_item.saturday_lunch == '' || the_item.saturday_lunch == null ? <Text style={{textAlign: 'left', backgroundColor: '#FFA500'}} onPress={e => handleEdit(the_item, "saturday lunch", "lunch")}>Edit</Text> : <Text style={{textAlign: 'left'}} onPress={e => handleEdit(the_item, "saturday lunch", "lunch")}>{the_item.saturday_lunch.map(({ id, travelTypeId, travelDistance }) => (<Text key={id} style={{padding:5}}> <Image source={travelTypeId == 1 ? require('../assets/car-icon.png') : travelTypeId == 2 ? require('../assets/cycle-icon.png') : travelTypeId == 3 ? require('../assets/walk-icon.png') : require('../assets/bus-icon.png')} style={styles.imageStyle} ></Image><Text style={styles.travelDistance}>{travelDistance}</Text> </Text>))}</Text>}
						</Text>
					</View>
				}
				{Moment(the_item.week_beginning).add(4, "days") > Moment(new Date()) ? null :
					<View style={styles.tableRow}>
						<Text style={styles.tableCellDate}>
						  {Moment(the_item.week_beginning).add(4, "days").format("ddd DD MMM")}
						</Text>
						<Text style={styles.tableCell}>
							{the_item.friday == '' || the_item.friday == null ? <Text style={{textAlign: 'left', backgroundColor: '#FFA500'}} onPress={e => handleEdit(the_item, "friday", "commute")}>Edit</Text> : <Text style={{textAlign: 'left'}} onPress={e => handleEdit(the_item, "friday", "commute")}>{the_item.friday.map(({ id, travelTypeId, travelDistance }) => (<Text key={id} style={{padding:5}}> <Image source={travelTypeId == 1 ? require('../assets/car-icon.png') : travelTypeId == 2 ? require('../assets/cycle-icon.png') : travelTypeId == 3 ? require('../assets/walk-icon.png') : require('../assets/bus-icon.png')} style={styles.imageStyle} ></Image><Text style={styles.travelDistance}>{travelDistance}</Text> </Text>))}</Text>}
						</Text>
						<Text style={styles.tableCell}>
							{the_item.friday_lunch == '' || the_item.friday_lunch == null ? <Text style={{textAlign: 'left', backgroundColor: '#FFA500'}} onPress={e => handleEdit(the_item, "friday lunch", "lunch")}>Edit</Text> : <Text style={{textAlign: 'left'}} onPress={e => handleEdit(the_item, "friday lunch", "lunch")}>{the_item.friday_lunch.map(({ id, travelTypeId, travelDistance }) => (<Text key={id} style={{padding:5}}> <Image source={travelTypeId == 1 ? require('../assets/car-icon.png') : travelTypeId == 2 ? require('../assets/cycle-icon.png') : travelTypeId == 3 ? require('../assets/walk-icon.png') : require('../assets/bus-icon.png')} style={styles.imageStyle} ></Image><Text style={styles.travelDistance}>{travelDistance}</Text> </Text>))}</Text>}
						</Text>
					</View>
				}
				{Moment(the_item.week_beginning).add(3, "days") > Moment(new Date()) ? null :
					<View style={styles.tableRow}>
						<Text style={styles.tableCellDate}>
						  {Moment(the_item.week_beginning).add(3, "days").format("ddd DD MMM")}
						</Text>
						<Text style={styles.tableCell}>
							{the_item.thursday == '' || the_item.thursday == null ? <Text style={{textAlign: 'left', backgroundColor: '#FFA500'}} onPress={e => handleEdit(the_item, "thursday", "commute")}>Edit</Text> : <Text style={{textAlign: 'left', height: 20}} onPress={e => handleEdit(the_item, "thursday", "commute")}>{the_item.thursday.map(({ id, travelTypeId, travelDistance }) => (<Text key={id} style={{padding:5}}> <Image source={travelTypeId == 1 ? require('../assets/car-icon.png') : travelTypeId == 2 ? require('../assets/cycle-icon.png') : travelTypeId == 3 ? require('../assets/walk-icon.png') : require('../assets/bus-icon.png')} style={styles.imageStyle} ></Image><Text style={styles.travelDistance}>{travelDistance}</Text> </Text>))}</Text>}
						</Text>
						<Text style={styles.tableCell}>
							{the_item.thursday_lunch == '' || the_item.thursday_lunch == null ? <Text style={{textAlign: 'left', backgroundColor: '#FFA500'}} onPress={e => handleEdit(the_item, "thursday lunch", "lunch")}>Edit</Text> : <Text style={{textAlign: 'left', height: 20}} onPress={e => handleEdit(the_item, "thursday lunch", "lunch")}>{the_item.thursday_lunch.map(({ id, travelTypeId, travelDistance }) => (<Text key={id} style={{padding:5}}> <Image source={travelTypeId == 1 ? require('../assets/car-icon.png') : travelTypeId == 2 ? require('../assets/cycle-icon.png') : travelTypeId == 3 ? require('../assets/walk-icon.png') : require('../assets/bus-icon.png')} style={styles.imageStyle} ></Image><Text style={styles.travelDistance}>{travelDistance}</Text> </Text>))}</Text>}
						</Text>
					</View>
				}
				{Moment(the_item.week_beginning).add(2, "days") > Moment(new Date()) ? null :
					<View style={styles.tableRow}>
						<Text style={styles.tableCellDate}>
						  {Moment(the_item.week_beginning).add(2, "days").format("ddd DD MMM")}
						</Text>
						<Text style={styles.tableCell}>
							{the_item.wednesday == '' || the_item.wednesday == null ? <Text style={{textAlign: 'left', backgroundColor: '#FFA500'}} onPress={e => handleEdit(the_item, "wednesday", "commute")}>Edit</Text> : <Text style={{textAlign: 'left'}} onPress={e => handleEdit(the_item, "wednesday", "commute")}>{the_item.wednesday.map(({ id, travelTypeId, travelDistance }) => (<Text key={id} style={{padding:5}}> <Image source={travelTypeId == 1 ? require('../assets/car-icon.png') : travelTypeId == 2 ? require('../assets/cycle-icon.png') : travelTypeId == 3 ? require('../assets/walk-icon.png') : require('../assets/bus-icon.png')} style={styles.imageStyle} ></Image><Text style={styles.travelDistance}>{travelDistance}</Text> </Text>))}</Text>}
						</Text>
						<Text style={styles.tableCell}>
							{the_item.wednesday_lunch == '' || the_item.wednesday_lunch == null ? <Text style={{textAlign: 'left', backgroundColor: '#FFA500'}} onPress={e => handleEdit(the_item, "wednesday lunch", "lunch")}>Edit</Text> : <Text style={{textAlign: 'left'}} onPress={e => handleEdit(the_item, "wednesday lunch", "lunch")}>{the_item.wednesday_lunch.map(({ id, travelTypeId, travelDistance }) => (<Text key={id} style={{padding:5}}> <Image source={travelTypeId == 1 ? require('../assets/car-icon.png') : travelTypeId == 2 ? require('../assets/cycle-icon.png') : travelTypeId == 3 ? require('../assets/walk-icon.png') : require('../assets/bus-icon.png')} style={styles.imageStyle} ></Image><Text style={styles.travelDistance}>{travelDistance}</Text> </Text>))}</Text>}
						</Text>
					</View>
				}
				{Moment(the_item.week_beginning).add(1, "days") > Moment(new Date()) ? null :
					<View style={styles.tableRow}>
						<Text style={styles.tableCellDate}>
						  {Moment(the_item.week_beginning).add(1, "days").format("ddd DD MMM")}
						</Text>
						<Text style={styles.tableCell}>
							{the_item.tuesday == '' || the_item.tuesday == null ? <Text style={{textAlign: 'left', backgroundColor: '#FFA500'}} onPress={e => handleEdit(the_item, "tuesday", "commute")}>Edit</Text> : <Text style={{textAlign: 'left'}} onPress={e => handleEdit(the_item, "tuesday", "commute")}>{the_item.tuesday.map(({ id, travelTypeId, travelDistance }) => (<Text key={id} style={{padding:5}}> <Image source={travelTypeId == 1 ? require('../assets/car-icon.png') : travelTypeId == 2 ? require('../assets/cycle-icon.png') : travelTypeId == 3 ? require('../assets/walk-icon.png') : require('../assets/bus-icon.png')} style={styles.imageStyle} ></Image><Text style={styles.travelDistance}>{travelDistance}</Text> </Text>))}</Text>}
						</Text>
						<Text style={styles.tableCell}>
							{the_item.tuesday_lunch == '' || the_item.tuesday_lunch == null ? <Text style={{textAlign: 'left', backgroundColor: '#FFA500'}} onPress={e => handleEdit(the_item, "tuesday lunch", "lunch")}>Edit</Text> : <Text style={{textAlign: 'left'}} onPress={e => handleEdit(the_item, "tuesday lunch", "lunch")}>{the_item.tuesday_lunch.map(({ id, travelTypeId, travelDistance }) => (<Text key={id} style={{padding:5}}> <Image source={travelTypeId == 1 ? require('../assets/car-icon.png') : travelTypeId == 2 ? require('../assets/cycle-icon.png') : travelTypeId == 3 ? require('../assets/walk-icon.png') : require('../assets/bus-icon.png')} style={styles.imageStyle} ></Image><Text style={styles.travelDistance}>{travelDistance}</Text> </Text>))}</Text>}
						</Text>
					</View>
				}
				<View style={styles.tableRow}>
					<Text style={styles.tableCellDate}>
					  {Moment(the_item.week_beginning).format("ddd DD MMM")}
					</Text>
					<Text style={styles.tableCell}>
						{the_item.monday == '' || the_item.monday == null ? <Text style={{textAlign: 'left', backgroundColor: '#FFA500'}} onPress={e => handleEdit(the_item, "monday", "commute")}>Edit</Text> : <Text style={{textAlign: 'left'}} onPress={e => handleEdit(the_item, "monday", "commute")}>{the_item.monday.map(({ id, travelTypeId, travelDistance }) => (<Text key={id} style={{padding:5}}> <Image source={travelTypeId == 1 ? require('../assets/car-icon.png') : travelTypeId == 2 ? require('../assets/cycle-icon.png') : travelTypeId == 3 ? require('../assets/walk-icon.png') : require('../assets/bus-icon.png')} style={styles.imageStyle} ></Image><Text style={styles.travelDistance}>{travelDistance}</Text> </Text>))}</Text>}
					</Text>
					<Text style={styles.tableCell}>
						{the_item.monday_lunch == '' || the_item.monday_lunch == null ? <Text style={{textAlign: 'left', backgroundColor: '#FFA500'}} onPress={e => handleEdit(the_item, "monday lunch", "lunch")}>Edit</Text> : <Text style={{textAlign: 'left'}} onPress={e => handleEdit(the_item, "monday lunch", "lunch")}>{the_item.monday_lunch.map(({ id, travelTypeId, travelDistance }) => (<Text key={id} style={{padding:5}}> <Image source={travelTypeId == 1 ? require('../assets/car-icon.png') : travelTypeId == 2 ? require('../assets/cycle-icon.png') : travelTypeId == 3 ? require('../assets/walk-icon.png') : require('../assets/bus-icon.png')} style={styles.imageStyle} ></Image><Text style={styles.travelDistance}>{travelDistance}</Text> </Text>))}</Text>}
					</Text>
				</View>
			</View>
			} />
			<VerticalSpace height={20} />
			<ConditionalButton onPress={loadPreviousPage} condition={startOfDays} text={'Forward 1 week'}/>
			<VerticalSpace height={20} />
			<ConditionalButton onPress={loadNextPage} condition={endOfDays} text={'Back 1 week'}/>
		</>
  )
}

const styles = StyleSheet.create({
  navigationBar: {
    // backgroundColor: Colors.primaryPurple, // AP
    backgroundColor: Colors.black, // Shine
    color: Colors.white, // Shine
    paddingBottom: 40,
    paddingTop: 40,
  },
  container: {
    paddingHorizontal: Styles.standardPadding,
  },
  innerContainer: {
	maxWidth: 1000,
    alignSelf: 'center',
    width: '100%',
  },
  tableColumn: {
   padding: 5,
   border: 'solid #99cff3',
   textAlign: 'left',
   width: '100%',
   fontSize: 18,
   fontWeight: 'bold',
  },
  tableColumnDate: {
   padding: 5,
   border: 'solid #99cff3',
   textAlign: 'left',
   width: 135,
   fontSize: 18,
   fontWeight: 'bold',
  },
  tableCell: {
   padding: 5,
   border: 'solid #99cff3',
   textAlign: 'left',
   width: '100%',
   fontSize: 18,
   backgroundColor: '#eeeeee',
  },
  tableCellDate: {
   padding: 5,
   border: 'solid #99cff3',
   textAlign: 'left',
   width: 135,
   fontSize: 18,
   backgroundColor: '#eeeeee',
  },
  tableHeader: {
   backgroundColor: '#cccccc',
   textAlign: 'left',
   fontWeight: 'bold',
   flexDirection: 'row',
   fontSize: 18,
  },
  tableRow: {
   backgroundColor: '#ffffff',
   textAlign: 'left',
   width: '100%',
   flexDirection: 'row',
   fontSize: 18,
   margin: 1,
  },
  travelDistance: {
	  textAlignVertical: 'top',
	  includeFontPadding: false,
  },
  imageStyle: {
	height: 20, width: 20,
	paddingTop: 10,
  }
})

