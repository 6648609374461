import React, { useEffect, useState } from "react";
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Button, FormGroup, FormControl } from "react-bootstrap";
import Api from '../components/Api'
import LabeledTextField from '../components/LabeledTextField'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import ConditionalButton from '../components/ConditionalButton'
import Heading from '../components/Heading'
import Para from '../components/Para'
import { BarPasswordStrengthDisplay } from 'react-native-password-strength-meter';

import styles from '../constants/Styles'
import Colors from '../constants/Colors'

import {
  useSearchParams
} from "react-router-dom";

export default function ConfirmPassword(props) {
	const [searchParams, setSearchParams] = useSearchParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [submitText, setSubmitText] = useState("Submit");
  
	const handleSubmit = () => {
		const api = new Api();
			api.UpdatePassword(password, searchParams.get("id"), function done(data) {
				window.location.href = '/passwordconfirmed';

		}, function notFound() {
			// not found
			//this.state.error = "Error";
			console.log('not found');
		});
	}

 const updatePassword = (text: string) => {
	  setPassword(text)
  }
  
 const updateConfirmPassword = (text: string) => {
	  setConfirmPassword(text)
  }
 
  
  const validateForm = () => {
    return password.length > 7 && confirmPassword == password && submitting == false;
  }

  return (
  		<KeyboardAwareScrollView style={styles.outerContainer}>
		  <View style={styles.innerContainer}>
			<NavigationBar logout={props.logout} goHome={props.goHome} user={props.user} ></NavigationBar>
		  </View>
		  <View style={styles.container, styles.greenStripe}>
			<View style={[styles.innerContainer, styles.container]}>
				<Heading fontsize={34} >Password reset</Heading>
			</View>
		  </View>
		  <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
				<VerticalSpace height={20} />
					<Para>Please enter your new password below</Para>
				<VerticalSpace height={20} />
					<FormGroup controlId="password" bsSize="large">
						<LabeledTextField placeholder="Required" password={true} label="New password" onChange={updatePassword} value={password} type="password" isValid={password != ''}/>
						<View style={styles.passwordStrength}>Strength: <BarPasswordStrengthDisplay password={password} /></View>
					</FormGroup>
					<FormGroup controlId="confirmPassword" bsSize="large">
						<LabeledTextField placeholder="Required" password={true} label="Confirm new password" onChange={updateConfirmPassword} value={confirmPassword} type="confirmPassword" isValid={password == confirmPassword}/>
					</FormGroup>
					<VerticalSpace height={40} />
					<ConditionalButton onPress={handleSubmit} condition={validateForm} text={submitText}/>
					<VerticalSpace height={20} />
			</View>
		  </View>
		  <FooterBar />
		</KeyboardAwareScrollView>


  );
}

