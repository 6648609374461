import React, { useEffect, useState } from "react";
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { createStackNavigator } from '@react-navigation/stack'
import { NavigationContainer } from '@react-navigation/native'
import Api from '../components/Api'
import LabeledTextField from '../components/LabeledTextField'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import ConditionalButton from '../components/ConditionalButton'
import Heading from '../components/Heading'
import Para from '../components/Para'
import AsyncStorage from '@react-native-async-storage/async-storage'

import Welcome from '../screens/Welcome'
import Register from '../screens/Register'
import Login from '../screens/Login'
import SetBaseline from '../screens/SetBaseline'
import SetLastWeek from '../screens/SetLastWeek'
import SetBaselineLunch from '../screens/SetBaselineLunch'
import SetLastWeekLunch from '../screens/SetLastWeekLunch'
import Ready from '../screens/Ready'
import WeekList from '../screens/WeekList'
import SetWeek from '../screens/SetWeek'
import SetDay from '../screens/SetDay'
import SetTarget from '../screens/SetTarget'
import SetPlan from '../screens/SetPlan'
import SetTargetLunch from '../screens/SetTargetLunch'
import SetPlanLunch from '../screens/SetPlanLunch'
import DoLunch from '../screens/DoLunch'
import Summary from '../screens/Summary'
import WalkChartDistance from '../screens/WalkChartDistance'
import HelpCentre from '../screens/HelpCentre'
import ResetPassword from '../screens/ResetPassword'
import ResetRequestConfirmed from '../screens/ResetRequestConfirmed' 
import MyAccount from '../screens/MyAccount' 
import AccountDeleted from '../screens/AccountDeleted' 

import Styles from '../constants/Styles'
import Colors from '../constants/Colors'

/*import {
  useSearchParams
} from "react-router-dom";*/

export default function Home(props) {
	const [screenname, SetScreenName] = useState('welcome')
	//const [searchParams, setSearchParams] = useSearchParams()
	const [selected_week_beginning, setSelectedWeekBeginning] = useState(null)
	const [selected_day, setSelectedDay] = useState(null)
	const [selected_week, setSelectedWeek] = useState(null)
	const [journey_type, setSelectedJourneyType] = useState(null)
	const [chart_type, setChartType] = useState("1")
	const [user, setUser] = useState(null)
  
	useEffect(() => {
			/*if (searchParams.get("x") == 'login')
			{
				SetScreenName('login')
			}*/
	}, [])
	
	const loginSucceeded = (user) => {
		if (user.car_distance != null)
		{
			setUser(user)
			SetScreenName('summary')
		}
		else
		{
			SetScreenName('setbaseline')
		}
	}
	
	const setWeek = (week, day, journey_type) => {
		setSelectedWeekBeginning(week.week_beginning)
		setSelectedDay(day)
		setSelectedWeek(week)
		setSelectedJourneyType(journey_type)
		SetScreenName('setDay')
	}
	
	
	const ShowChart = (type) => {
		setChartType(type)
		SetScreenName('walkChartDistance')
	}
	
	const logout = () => {
		AsyncStorage.removeItem('user');
		setUser(null)
		SetScreenName('login')
	}
	
	const confirmDelete = () => {
		SetScreenName('deleteconfirmed')
		AsyncStorage.removeItem('user');
		setUser(null)
	}
	
	const goHome = () => {
		if (user == null)
		{
			SetScreenName('welcome')
		}
		else
		{
			SetScreenName('summary')
		}
	}
	
	const doesDoLunch = (doLunch) => {
		if (doLunch)
		{
			SetScreenName('setbaselinelunch')
		}
		else
		{
			SetScreenName('ready')
		}
	}
	

   const Screen = (): ReactElement => {
	  if (screenname == 'welcome') {
		  console.log(screenname)
			return <Welcome register={() => SetScreenName('register')} login={() => SetScreenName('login')} logout={logout} goHome={goHome} user={user} />
	  }
	  else if (screenname == 'register') {
			return <Register onRegistered={() => SetScreenName('setbaseline')} GoBack={() => SetScreenName('welcome')} logout={logout} goHome={goHome} user={user} />
	  }
	  else if (screenname == 'setbaseline') {
			return <SetBaseline onBaselineSet={() => SetScreenName('setlastweek')} GoBack={() => SetScreenName('register')} logout={logout} goHome={goHome} user={user} />
	  }
	  else if (screenname == 'setlastweek') {
			return <SetLastWeek onBaselineSet={() => SetScreenName('settarget')} GoBack={() => SetScreenName('setbaseline')} logout={logout} goHome={goHome} user={user} />
	  }
	  else if (screenname == 'settarget') {
			return <SetTarget onTargetSet={() => SetScreenName('setplan')} GoBack={() => SetScreenName('setlastweek')} logout={logout} goHome={goHome} user={user} />
	  }  
	  else if (screenname == 'setplan') {
			return <SetPlan onPlanSet={() => SetScreenName('dolunch')} GoBack={() => SetScreenName('settarget')} logout={logout} goHome={goHome} user={user} />
	  }  
	  else if (screenname == 'dolunch') {
			return <DoLunch doesDoLunch={(e) => doesDoLunch(e)} GoBack={() => SetScreenName('setplan')} logout={logout} goHome={goHome} user={user} />
	  }  
	  else if (screenname == 'setbaselinelunch') {
			return <SetBaselineLunch onBaselineSet={() => SetScreenName('setlastweeklunch')} GoBack={() => SetScreenName('dolunch')} logout={logout} goHome={goHome} user={user} />
	  }
	  else if (screenname == 'setlastweeklunch') {
			return <SetLastWeekLunch onBaselineSet={() => SetScreenName('settargetlunch')} GoBack={() => SetScreenName('setbaselinelunch')} logout={logout} goHome={goHome} user={user} />
	  }
	  else if (screenname == 'settargetlunch') {
			return <SetTargetLunch onTargetSet={() => SetScreenName('setplanlunch')} GoBack={() => SetScreenName('setlastweeklunch')} logout={logout} goHome={goHome} user={user} />
	  }  
	  else if (screenname == 'setplanlunch') {
			return <SetPlanLunch onPlanSet={() => SetScreenName('ready')} GoBack={() => SetScreenName('settargetlunch')} logout={logout} goHome={goHome} user={user} />
	  }  
	  else if (screenname == 'ready') {
			return <Ready logout={logout} goHome={goHome} user={user} />
	  }
	  else if (screenname == 'login') {
			return <Login loginSucceeded={loginSucceeded} GoToScreen={SetScreenName} logout={logout} goHome={goHome} user={user} />
	  }
	  else if (screenname == 'privacy') {
			return <Privacy onRegistered={() => SetScreenName('setbaseline')} logout={logout} goHome={goHome} user={user} />
	  }
	  else if (screenname == 'weeklist') {
			return <WeekList setWeek={setWeek} GoToScreen={SetScreenName} logout={logout} goHome={goHome} user={user} />
	  }
	  else if (screenname == 'summary') {
			return <Summary GoToScreen={SetScreenName} ShowChart ={ShowChart} logout={logout} goHome={goHome} user={user} />
	  }
	  else if (screenname == 'walkChartDistance') {
			return <WalkChartDistance type={chart_type} GoToScreen={SetScreenName} logout={logout} goHome={goHome} user={user} />
	  }
	  else if (screenname == 'helpcentre') {
			return <HelpCentre GoToScreen={SetScreenName} logout={logout} goHome={goHome} user={user} />
	  }
	  else if (screenname == 'myaccount') {
			return <MyAccount GoToScreen={SetScreenName} logout={logout} goHome={goHome} confirmDelete={confirmDelete} user={user} />
	  }
	  else if (screenname == 'setWeek') {
			return <SetWeek week_beginning={selected_week_beginning} week={selected_week} onWeekSet={() => SetScreenName('weeklist')} logout={logout} goHome={goHome} user={user} />
	  }
	  else if (screenname == 'setDay') {
			return <SetDay week_beginning={selected_week_beginning} week={selected_week} day={selected_day} journey_type={journey_type} onDaySet={() => SetScreenName('weeklist')} logout={logout} goHome={goHome} user={user} />
	  }
	  else if (screenname == 'resetPassword') {
			return <ResetPassword requestSucceeded={() => SetScreenName('resetrequestconfirmed')} logout={logout} goHome={goHome} user={user} />
	  }
	  else if (screenname == 'resetrequestconfirmed') {
			return <ResetRequestConfirmed GoBack={() => SetScreenName('welcome')} logout={logout} goHome={goHome} user={user} />
	  }
	  else if (screenname == 'deleteconfirmed') {
			return <AccountDeleted goHome={goHome} register={() => SetScreenName('register')} login={() => SetScreenName('login')} />
	  }
   }
   

	const Stack = createStackNavigator()
	
  return (
      <NavigationContainer>
        <Stack.Navigator
          screenOptions={{
            cardStyle: {
              backgroundColor: '#ffffff'
            },
            headerShown: false
          }}>
          <Stack.Screen name="Welcome" component={Screen} />
        </Stack.Navigator>
      </NavigationContainer>
  );  
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
