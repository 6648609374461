import React, { FunctionComponent } from 'react'
import { GestureResponderEvent, Pressable, StyleSheet, Text } from 'react-native'
import Colors from '../constants/Colors'

type DefaultButtonProps = {
  text: string
  onPress: (event: GestureResponderEvent) => void
}

const DefaultButton: FunctionComponent<DefaultButtonProps> = (props: DefaultButtonProps) => {
  return (
    <Pressable style={styles.defaultButton} onPress={props.onPress}>
      <Text style={styles.buttonText}>{props.text}</Text>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  buttonText: {
    color: '#ffffff',
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  defaultButton: {
    backgroundColor: Colors.green,
    borderRadius: 4,
    padding: 10
  }
})

export default DefaultButton
