import React, { useEffect, useState } from "react";
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Button, FormGroup, FormControl } from "react-bootstrap";
import Api from '../components/Api'
import LabeledNumberField from '../components/LabeledNumberField'
import LabeledDropDown from '../components/LabeledDropDown'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import DefaultButton from '../components/DefaultButton'
import ConditionalButton from '../components/ConditionalButton'
import SwitchButton from '../components/SwitchButton'
import Heading from '../components/Heading'
import Para from '../components/Para'
import Moment from 'moment';
import AsyncStorage from '@react-native-async-storage/async-storage'

import styles from '../constants/Styles'
import Colors from '../constants/Colors'

export default function SetDay(props) {
  const [car_visible, setCarVisible] = useState(false);
  const [cycle_visible, setCycleVisible] = useState(false);
  const [walk_visible, setWalkVisible] = useState(false);
  const [pt_visible, setPTVisible] = useState(false);
  const [car, setCar] = useState("0");
  const [cycle, setCycle] = useState("0");
  const [walk, setWalk] = useState("0");
  const [publicTransport, setPublicTransport] = useState("0");
  const [submit_button_text, setSubmitButtonText] = useState("Submit");
  const [submitted, setSubmitted] = useState(false);
  const [an_error, setError] = useState(false);

   const toggleCar = () => {
	  setCarVisible(!car_visible)
  }

   const toggleCycle = () => {
	  setCycleVisible(!cycle_visible)
  }

   const toggleWalk = () => {
	  setWalkVisible(!walk_visible)
  }

   const togglePT = () => {
	  setPTVisible(!pt_visible)
  }

  
 const updateCar = (text: string) => {
	  setCar(text)
  }
  
 const updateCycle = (text: string) => {
	  setCycle(text)
  }
  
 const updateWalk = (text: string) => {
	  setWalk(text)
  }
  
 const updatePublicTransport = (text: string) => {
	  setPublicTransport(text)
  }
  function isNumeric(value) {
		return /^-?\d+$/.test(value);
	}
  
  const validateForm = () => {
    return !submitted;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
		AsyncStorage.getItem('user').then((value) => {
			const user = value
			setSubmitted(true)
			setSubmitButtonText("Please wait")

			const api = new Api();
			api.submitTravelRecord(user, props.week_beginning, function done(data) {
				const travel_record_id = data.id
				var car_distance = "0";
				var cycle_distance = "0";
				var walk_distance = "0";
				var pt_distance = "0";
				
				if (car_visible)
				{
					car_distance = car
				}
				if (cycle_visible)
				{
					cycle_distance = cycle
				}
				if (walk_visible)
				{
					walk_distance = walk
				}
				if (pt_visible)
				{
					pt_distance = publicTransport
				}
				
				api.submitTravelRecordDetails(travel_record_id, props.day, "1", car_distance, function done(data) {
					api.submitTravelRecordDetails(travel_record_id, props.day, "2", cycle_distance, function done(data) {
						api.submitTravelRecordDetails(travel_record_id, props.day, "3", walk_distance, function done(data) {
							api.submitTravelRecordDetails(travel_record_id, props.day, "4", pt_distance, function done(data) {
										props.onDaySet()

							}, function notFound() {
								// not found
								setError(true)
								console.log('not found');
								setSubmitButtonText("Submit")
								setSubmitted(false)
							});
								
						}, function notFound() {
							// not found
							setError(true)
							console.log('not found');
							setSubmitButtonText("Submit")
							setSubmitted(false)
						});
							
					}, function notFound() {
						// not found
						setError(true)
						console.log('not found');
						setSubmitButtonText("Submit")
						setSubmitted(false)
					});
						
				}, function notFound() {
					// not found
					setError(true)
					console.log('not found');
					setSubmitButtonText("Submit")
					setSubmitted(false)
				});
					
			}, function notFound() {
				// not found
				setError(true)
				console.log('not found');
				setSubmitButtonText("Submit")
				setSubmitted(false)
			});
		})
  }
  
  	useEffect(() => {
		if (props.journey_type == "lunch")
		{
			AsyncStorage.getItem('car_distance_lunch').then((value) => {
				setCar(value)
				AsyncStorage.getItem('bike_distance_lunch').then((value) => {
					setCycle(value)
					AsyncStorage.getItem('walk_distance_lunch').then((value) => {
						setWalk(value)
						AsyncStorage.getItem('public_transport_distance_lunch').then((value) => {
							setPublicTransport(value)
						})
					})
				})
			})
		}
		else
		{
			AsyncStorage.getItem('car_distance').then((value) => {
				setCar(value)
				AsyncStorage.getItem('bike_distance').then((value) => {
					setCycle(value)
					AsyncStorage.getItem('walk_distance').then((value) => {
						setWalk(value)
						AsyncStorage.getItem('public_transport_distance').then((value) => {
							setPublicTransport(value)
						})
					})
				})
			})
		}

		if (props.journey_type == "lunch")
		{
		  if (props.day == "monday lunch")
		  {
			for(let i = 0; i < props.week.monday_lunch.length; i++) {
				let obj = props.week.monday_lunch[i];
				SetDetails(obj)
			}
		  }
		  else if (props.day == "tuesday lunch")
		  {
			for(let i = 0; i < props.week.tuesday_lunch.length; i++) {
				let obj = props.week.tuesday_lunch[i];
				SetDetails(obj)
			}
		  }
		  else if (props.day == "wednesday lunch")
		  {
			for(let i = 0; i < props.week.wednesday_lunch.length; i++) {
				let obj = props.week.wednesday_lunch[i];
				SetDetails(obj)
			}
		  }
		  else if (props.day == "thursday lunch")
		  {
			for(let i = 0; i < props.week.thursday_lunch.length; i++) {
				let obj = props.week.thursday_lunch[i];
				SetDetails(obj)
			}
		  }
		  else if (props.day == "friday lunch")
		  {
			for(let i = 0; i < props.week.friday_lunch.length; i++) {
				let obj = props.week.friday_lunch[i];
				SetDetails(obj)
			}
		  }
		  else if (props.day == "saturday lunch")
		  {
			for(let i = 0; i < props.week.saturday_lunch.length; i++) {
				let obj = props.week.saturday_lunch[i];
				SetDetails(obj)
			}
		  }
		  else if (props.day == "sunday lunch")
		  {
			for(let i = 0; i < props.week.sunday_lunch.length; i++) {
				let obj = props.week.sunday_lunch[i];
				SetDetails(obj)
			}
		  }
		}
		else
		{
		  if (props.day == "monday")
		  {
			for(let i = 0; i < props.week.monday.length; i++) {
				let obj = props.week.monday[i];
				SetDetails(obj)
			}
		  }
		  else if (props.day == "tuesday")
		  {
			for(let i = 0; i < props.week.tuesday.length; i++) {
				let obj = props.week.tuesday[i];
				SetDetails(obj)
			}
		  }
		  else if (props.day == "wednesday")
		  {
			for(let i = 0; i < props.week.wednesday.length; i++) {
				let obj = props.week.wednesday[i];
				SetDetails(obj)
			}
		  }
		  else if (props.day == "thursday")
		  {
			for(let i = 0; i < props.week.thursday.length; i++) {
				let obj = props.week.thursday[i];
				SetDetails(obj)
			}
		  }
		  else if (props.day == "friday")
		  {
			for(let i = 0; i < props.week.friday.length; i++) {
				let obj = props.week.friday[i];
				SetDetails(obj)
			}
		  }
		  else if (props.day == "saturday")
		  {
			for(let i = 0; i < props.week.saturday.length; i++) {
				let obj = props.week.saturday[i];
				SetDetails(obj)
			}
		  }
		  else if (props.day == "sunday")
		  {
			for(let i = 0; i < props.week.sunday.length; i++) {
				let obj = props.week.sunday[i];
				SetDetails(obj)
			}
		  }
		}

	}, [])
  
  const SetDetails = (obj) => {
	if (obj.travelTypeId == 1)
	{
		setCar(obj.travelDistance)
		setCarVisible(true)
	}
	else if (obj.travelTypeId == 2)
	{
		setCycle(obj.travelDistance)
		setCycleVisible(true)
	}
	else if (obj.travelTypeId == 3)
	{
		setWalk(obj.travelDistance)
		setWalkVisible(true)			
	}
	else if (obj.travelTypeId == 4)
	{
		setPublicTransport(obj.travelDistance)
		setPTVisible(true)
	}
  }
  
  return (
  		<KeyboardAwareScrollView style={styles.outerContainer}>
		  <View style={styles.innerContainer}>
			<NavigationBar logout={props.logout} goHome={props.goHome} user={props.user} ></NavigationBar>
		  </View>
		  <View style={styles.container, styles.greenStripe}>
			<View style={[styles.innerContainer, styles.container]}>
				<Heading fontsize={34} >Daily update</Heading>
			</View>
		  </View>
		  <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
				<VerticalSpace height={20} />
					<Para>Which mode(s) of transport did you use on {props.day}</Para>
						<SwitchButton selected={car_visible} onPress={toggleCar} text="Car"/>
						<LabeledNumberField visible={car_visible} placeholder="Required" label="How many miles did you travel by car" onChange={updateCar} value={car}/>
					<VerticalSpace height={20} />
						<SwitchButton selected={cycle_visible} onPress={toggleCycle} text="Cycle"/>
						<LabeledNumberField visible={cycle_visible} placeholder="Required" label="How many miles did you cycle" onChange={updateCycle} value={cycle}/>
					<VerticalSpace height={20} />
						<SwitchButton selected={walk_visible} onPress={toggleWalk} text="Walk"/>
						<LabeledNumberField visible={walk_visible} placeholder="Required" label="How many miles did you walk" onChange={updateWalk} value={walk}/>
					<VerticalSpace height={20} />
						<SwitchButton selected={pt_visible} onPress={togglePT} text="Public Transport"/>
						<LabeledNumberField visible={pt_visible} placeholder="Required" label="How many miles did you travel by public transport" onChange={updatePublicTransport} value={publicTransport}/>
					<VerticalSpace height={40} />
						{ an_error == false ? null :
						<View>
							<Para>There was an error. Please check your internet connection and try again</Para>
							<VerticalSpace height={40} />
						</View>
						}
					<ConditionalButton onPress={handleSubmit} condition={validateForm} text={submit_button_text}/>
				<VerticalSpace height={20} />
			</View>
		  </View>
		  <FooterBar />
		</KeyboardAwareScrollView>


  );
}

