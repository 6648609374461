import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Linking } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Button, FormGroup, FormControl } from "react-bootstrap";
import Api from '../components/Api'
import LabeledTextField from '../components/LabeledTextField'
import LabeledDropDown from '../components/LabeledDropDown'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import DefaultButton from '../components/DefaultButton'
import Heading from '../components/Heading'
import Para from '../components/Para'
import Moment from 'moment';
import { Card, Tile } from 'react-native-elements';

import styles from '../constants/Styles'
import Colors from '../constants/Colors'

export default function HelpCentre(props) {
	const handleSubmit = () => {
		props.GoToScreen('summary')
	}

  return (
  		<KeyboardAwareScrollView style={styles.outerContainer}>
		  <View style={styles.innerContainer}>
			<NavigationBar logout={props.logout} goHome={props.goHome} user={props.user} ></NavigationBar>
		  </View>
		  <View style={styles.container, styles.greenStripe}>
			<View style={[styles.innerContainer, styles.container]}>
				<Heading fontsize={34} >Resource Centre</Heading>
			</View>
		  </View>
		  <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
			  <Para>
				  For further information on travelling actively and sustainably please visit Warwickshire County Councils Safe and Active Webpage:
			  </Para>
			  <Para>
				<Text style={styles.linkstyle} onPress={() => Linking.openURL('http://www.safeactivetravelwcc.co.uk')}>www.safeactivetravelwcc.co.uk</Text>
			  </Para>
				<VerticalSpace height={20} />
				<DefaultButton onPress={handleSubmit} text={"Done"}/>
				<VerticalSpace height={20} />
			</View>
		  </View>
		  <FooterBar />
		</KeyboardAwareScrollView>


  );
}
