import React from 'react'
import { Text } from 'react-native'
import Colors from '../constants/Colors'

type HeadingProps = {
  children: string
  fontsize: any
  color: any
}

const Heading = ({ children, fontsize, color }: HeadingProps) => {
  return <Text style={{ color: color ? color : Colors.green, fontSize: fontsize ? fontsize: 18, fontWeight: 'bold', marginBottom: 5, marginTop: 15, width: '100%', textAlign: 'center' }}>{children}</Text>
}

export default Heading