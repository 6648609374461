import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Button, FormGroup, FormControl } from "react-bootstrap";
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import DefaultButton from '../components/DefaultButton'
import Heading from '../components/Heading'
import Para from '../components/Para'

import styles from '../constants/Styles'
import Colors from '../constants/Colors'
import AsyncStorage from '@react-native-async-storage/async-storage'

export default function DoLunch(props) {
  
  	useEffect(() => {
		AsyncStorage.setItem('walkDistanceLunch', 0)
	}, [])
  
  const handleBack = () => {
	  props.GoBack();
  }

  return (
  		<KeyboardAwareScrollView style={styles.outerContainer}>
		  <View style={styles.innerContainer}>
			<NavigationBar logout={props.logout} goHome={props.goHome} user={props.user} ></NavigationBar>
		  </View>
		  <View style={styles.container, styles.greenStripe}>
			<View style={[styles.innerContainer, styles.container]}>
				<Heading fontsize={34} >Register</Heading>
			</View>
		  </View>
		  <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
				<VerticalSpace height={20} />
				<Para>Do you go out at lunch time?</Para>
					<DefaultButton onPress={() => props.doesDoLunch(true)} text={'Yes'}/>
					<VerticalSpace height={20} />
					<DefaultButton onPress={() => props.doesDoLunch(false)} text={'No'}/>
					<VerticalSpace height={40} />
					<DefaultButton onPress={handleBack} text={'Back'}/>
				<VerticalSpace height={20} />
			</View>
		  </View>
		  <FooterBar />
		</KeyboardAwareScrollView>


  );
}
