import React, { useState } from "react";
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Button, FormGroup, FormControl } from "react-bootstrap";
import Api from '../components/Api'
import LabeledTextField from '../components/LabeledTextField'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import ConditionalButton from '../components/ConditionalButton'
import Heading from '../components/Heading'
import Para from '../components/Para'

import styles from '../constants/Styles'
import Colors from '../constants/Colors'

export default function Terms(props) {
  
  const handleSubmit = (event) => {
    event.preventDefault();
	
  }
  
  return (
  		<KeyboardAwareScrollView style={styles.outerContainer}>
		  <View style={styles.innerContainer}>
			<NavigationBar logout={props.logout} goHome={props.goHome} user={props.user} ></NavigationBar>
		  </View>
		  <View style={styles.container, styles.greenStripe}>
			<View style={[styles.innerContainer, styles.container]}>
				<Heading fontsize={34} >Terms and Conditions</Heading>
			</View>
		  </View>
		  <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
				<VerticalSpace height={20} />
<Para>Please read these terms and conditions ("terms and conditions", "terms") carefully before using The JustOneJourney app (“Application”, "service") operated by NVC Limited ("us", 'we", "our").</Para>
<Para bold={true}>Conditions of use</Para>
<Para>By using this app, you certify that you have read and reviewed this Agreement and that you agree to comply with its terms. If you do not want to be bound by the terms of this Agreement, you are advised to leave the app accordingly. NVC Limited only grants use and access of this app, its products, and its services to those who have accepted its terms.</Para>
<Para bold={true}>Privacy policy</Para>
<Para>Before you continue using our app, we advise you to read our <a href="https://justonejourney.aktiv8change.co.uk/privacy" target="_blank" >privacy policy</a> regarding our user data collection. It will help you better understand our practices.</Para>
<Para bold={true}>User accounts</Para>
<Para>As a user of this app, you may be asked to register with us and provide private information. You are responsible for ensuring the accuracy of this information, and you are responsible for maintaining the safety and security of your identifying information. You are also responsible for all activities that occur under your account or password.</Para>
<Para>If you think there are any possible issues regarding the security of your account on the app, inform us immediately so we may address them accordingly.</Para>
<Para>We reserve all rights to terminate accounts, edit or remove content and cancel orders at our sole discretion.</Para>

<Para bold={true}>Applicable law</Para>
<Para>These Terms are governed by the law of England and Wales. All disputes under these terms shall be subject to the exclusive jurisdiction of the courts of England and Wales. </Para>
<Para bold={true}>Indemnification</Para>
<Para>You agree to indemnify NVC Limited and its affiliates and hold NVC Limited harmless against legal claims and demands that may arise from your use or misuse of our services. </Para>
<Para bold={true}>Limitation on liability</Para>
<Para>NVC Limited is not liable for any damages that may occur to you as a result of your misuse of our app.</Para>
<Para>NVC Limited reserves the right to edit, modify, and change this Agreement at any time. We shall let our users know of these changes through electronic mail. This Agreement is an understanding between NVC Limited and the user, and this supersedes and replaces all prior agreements regarding the use of this website.</Para>

 

 



					<VerticalSpace height={40} />
				<VerticalSpace height={20} />
			</View>
		  </View>
		  <FooterBar />
		</KeyboardAwareScrollView>


  );
}

