import React, { useEffect, useState } from "react";
import { ImageBackground, StyleSheet, View, Text, Linking } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import DefaultButton from '../components/DefaultButton'
import Heading from '../components/Heading'
import Para from '../components/Para'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'

import Colors from '../constants/Colors'
import AsyncStorage from '@react-native-async-storage/async-storage'

import Styles from '../constants/Styles'

interface WelcomeProps {
  navigation: any
  register: Function
  login: Function
}


export default function AccountDeleted ({ navigation, register, login }: WelcomeProps) {
  return (
    <KeyboardAwareScrollView style={Styles.outerContainer}>
	  <View style={Styles.innerContainer}>
		<NavigationBar navigation={navigation} logout={login}></NavigationBar>
	  </View>
	  <View style={Styles.container, Styles.greenStripe}>
		<View style={[Styles.innerContainer, Styles.container]}>
			<Heading fontsize={34} >Account deleted</Heading>
			<VerticalSpace height={10} />
			</View>
	  </View>
      <View style={[Styles.container, Styles.jojWelcome]}>
			<View style={[Styles.innerContainer, Styles.container]}>
				<VerticalSpace height={20} />
				<Para>Your account has been deleted. Thanks for being part of the JustOneJourney scheme. We are sorry to see you go</Para>
				<VerticalSpace height={20} />
				<DefaultButton onPress={() => register()} text={'Register'} />
				<VerticalSpace height={20} />
				<DefaultButton onPress={() => login()} text={'Log in'} />
				<VerticalSpace height={20} />
			</View>
	  </View>
      <View style={[Styles.acWelcome]}>
			<View style={[Styles.innerContainer, Styles.container]}>
				<VerticalSpace height={20} />
				<Para>Your account has been deleted. Thanks for being part of the aktiv8Change scheme. We are sorry to see you go</Para>
				<VerticalSpace height={20} />
				<DefaultButton onPress={() => register()} text={'Register'} />
				<VerticalSpace height={20} />
				<DefaultButton onPress={() => login()} text={'Log in'} />
				<VerticalSpace height={20} />
			</View>
      </View>
	  <FooterBar />
    </KeyboardAwareScrollView>
  )
}


