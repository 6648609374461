import React, { useEffect, useState } from "react";
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Button, FormGroup, FormControl } from "react-bootstrap";
import Api from '../components/Api'
import LabeledTextField from '../components/LabeledTextField'
import LabeledDropDown from '../components/LabeledDropDown'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import ConditionalButton from '../components/ConditionalButton'
import Heading from '../components/Heading'
import Para from '../components/Para'
import Moment from 'moment';

import styles from '../constants/Styles'
import Colors from '../constants/Colors'

export default function SetWeek(props) {
  const [monday, setMonday] = useState("");
  const [tuesday, setTuesday] = useState("");
  const [wednesday, setWednesday] = useState("");
  const [thursday, setThursday] = useState("");
  const [friday, setFriday] = useState("");
  const [saturday, setSaturday] = useState("");
  const [sunday, setSunday] = useState("");
  const [mondayDate, setMondayDate] = useState("");
  const [tuesdayDate, setTuesdayDate] = useState("");
  const [wednesdayDate, setWednesdayDate] = useState("");
  const [thursdayDate, setThursdayDate] = useState("");
  const [fridayDate, setFridayDate] = useState("");
  const [saturdayDate, setSaturdayDate] = useState("");
  const [sundayDate, setSundayDate] = useState("");
  
	useEffect(() => {
		var date = new Date(props.week_beginning)
		setMondayDate(date)
		console.log(date)

		var tuesday_date = new Date(props.week_beginning)
		tuesday_date.setDate(tuesday_date.getDate() + 1);
		setTuesdayDate(tuesday_date)

		var wednesday_date = new Date(props.week_beginning)
		wednesday_date.setDate(wednesday_date.getDate() + 2);
		setWednesdayDate(wednesday_date)

		var thursday_date = new Date(props.week_beginning)
		thursday_date.setDate(thursday_date.getDate() + 3);
		setThursdayDate(thursday_date)

		var friday_date = new Date(props.week_beginning)
		friday_date.setDate(friday_date.getDate() + 4);
		setFridayDate(friday_date)

		var saturday_date = new Date(props.week_beginning)
		saturday_date.setDate(saturday_date.getDate() + 5);
		setSaturdayDate(saturday_date)

		var sunday_date = new Date(props.week_beginning)
		sunday_date.setDate(sunday_date.getDate() + 6);
		setSundayDate(sunday_date)
		
		setMonday(props.week.monday)
		setTuesday(props.week.tuesday)
		setWednesday(props.week.wednesday)
		setThursday(props.week.thursday)
		setFriday(props.week.friday)
		setSaturday(props.week.saturday)
		setSunday(props.week.sunday)

	}, [])

 const updateMonday = (text: string) => {
 	  setMonday(text == null ? '' : text)
  }
  
 const updateTuesday = (text: string) => {
 	  setTuesday(text == null ? '' : text)
  }
  
 const updateWednesday = (text: string) => {
 	  setWednesday(text == null ? '' : text)
  }
  
 const updateThursday = (text: string) => {
 	  setThursday(text == null ? '' : text)
  }
  
 const updateFriday = (text: string) => {
 	  setFriday(text == null ? '' : text)
  }
  
 const updateSaturday = (text: string) => {
 	  setSaturday(text == null ? '' : text)
  }
  
 const updateSunday = (text: string) => {
 	  setSunday(text == null ? '' : text)
  }

  const transportItems = [
                { label: 'Car', value: 'car' },
                { label: 'Cycle', value: 'cycle' },
                { label: 'Walk', value: 'walk' },
                { label: 'Public transport', value: 'public' },
                { label: 'Other', value: 'other' },
                { label: 'I worked from home', value: 'home' },
                { label: 'I didnt work that day', value: 'off' },
            ]
  
  const handleSubmit = (event) => {
    event.preventDefault();
	const user = localStorage.getItem('user')

	const api = new Api();
	api.submitWeeklyReport(user, props.week_beginning, monday, tuesday, wednesday, thursday, friday, saturday, sunday, "N", function done(data) {
			props.onWeekSet()
			
	}, function notFound() {
		// not found
		//this.state.error = "Error";
		console.log('not found');
	});
  }
  
  const validateForm = () => {
    return true;//monday.length > 0 && tuesday.length > 0 && wednesday.length > 0 && thursday.length > 0 && friday.length > 0 && saturday.length > 0 && sunday.length > 0;
  }
  
  return (
  		<KeyboardAwareScrollView style={styles.outerContainer}>
		  <View style={styles.innerContainer}>
			<NavigationBar logout={props.logout} goHome={props.goHome} user={props.user} ></NavigationBar>
		  </View>
		  <View style={styles.container, styles.greenStripe}>
			<View style={[styles.innerContainer, styles.container]}>
				<Heading fontsize={34} >Weekly update</Heading>
			</View>
		  </View>
		  <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
				<VerticalSpace height={20} />
					<Para>For the week beginning {Moment(props.week_beginning).format("DD/MM/YYYY")} how did you travel to work?</Para>
					{mondayDate < new Date() ?
					<FormGroup controlId="monday" bsSize="large">
						<LabeledDropDown items={transportItems} placeholder="Required" label="Monday" onChange={updateMonday} value={monday}/>
					</FormGroup> : null}
					{tuesdayDate < new Date() ?
					<FormGroup controlId="tuesday" bsSize="large">
						<LabeledDropDown items={transportItems} placeholder="Required" label="Tuesday" onChange={updateTuesday} value={tuesday}/>
					</FormGroup> : null}
					{wednesdayDate < new Date() ?
					<FormGroup controlId="wednesday" bsSize="large">
						<LabeledDropDown items={transportItems} placeholder="Required" label="Wednesday" onChange={updateWednesday} value={wednesday}/>
					</FormGroup> : null}
					{thursdayDate < new Date() ?
					<FormGroup controlId="thursday" bsSize="large">
						<LabeledDropDown items={transportItems} placeholder="Required" label="Thursday" onChange={updateThursday} value={thursday}/>
					</FormGroup> : null}
					{fridayDate < new Date() ?
					<FormGroup controlId="friday" bsSize="large">
						<LabeledDropDown items={transportItems} placeholder="Required" label="Friday" onChange={updateFriday} value={friday}/>
					</FormGroup> : null}
					{saturdayDate < new Date() ?
					<FormGroup controlId="saturday" bsSize="large">
						<LabeledDropDown items={transportItems} placeholder="Required" label="Saturday" onChange={updateSaturday} value={saturday}/>
					</FormGroup> : null}
					{sundayDate < new Date() ?
					<FormGroup controlId="sunday" bsSize="large">
						<LabeledDropDown items={transportItems} placeholder="Required" label="Sunday" onChange={updateSunday} value={sunday}/>
					</FormGroup> : null}
					<VerticalSpace height={40} />
					<ConditionalButton onPress={handleSubmit} condition={validateForm} text="Submit"/>
				<VerticalSpace height={20} />
			</View>
		  </View>
		  <FooterBar />
		</KeyboardAwareScrollView>


  );
}

