import { StyleSheet, useState, useEffect } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage'

import Colors from '../constants/Colors'

export default StyleSheet.create({
  outerContainer: {
	alignSelf: 'center',
	width: '100%',
	backgroundColor: '#99cff3',
  },
  container: {
	paddingHorizontal: 15,
  },
  greenStripe: {
	  color: Colors.green,
	  textAlign: 'center',
  },
  violetStripe: {
	  backgroundColor: Colors.violet,
	  color: '#ffffff',
  },
  innerContainer: {
	maxWidth: 1000,
	alignSelf: 'center',
	width: '100%',
	color: '#000000',
	flex: 1,
  },
  imageWrapper: {
	  textAlign: 'center',
	  justifyContent: 'center',
	alignItems: 'center'
  },
  waitingimgstyle: {
	maxWidth: '200px'
  },
  linkstyle: {
	  color: '#0000ff',
	  textDecorationLine: 'underline'
  },
  passwordStrength: {
	  fontSize: 14,
	  paddingTop: 10,
	  fontFamily: 'Arial',
	  width: '98%',
	  overflow: 'hidden',
  },
  modal: {
	  backgroundColor: '#ffffff',
	  padding: 30,
	  margin: 30,
  },
  highlightedText: {
	  color: Colors.green,
	  fontWeight: 'bold',
	  fonstSize: 30,
  },
  tileContainer: {
	  color: '#ffffff',
	  backgroundColor: Colors.green,
	  textAlign: 'center',
	  border: 'none',
	  borderRadius: 10,
  },
  tileContainerWhite: {
	  color: Colors.green,
	  backgroundColor: '#ffffff',
	  textAlign: 'center',
	  border: 'none',
	  borderRadius: 10,
  },
  tilePara: {
	  color: '#ffffff',
  },
  imageWrapper: {
	  textAlign: 'center',
	  justifyContent: 'center',
	alignItems: 'center',
  },
  waitingimgstyle: {
	width: 100,
	height: 100
  },
  chartContainer: {
	  textAlign: 'left',
  },
  acWelcome: {
	  height: 0,
	  overflow: 'hidden',
  },

  standardPadding: 15,
  headerPadding: 10,
})
