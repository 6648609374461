import each from 'async/each';
import {decode as atob, encode as btoa} from 'base-64'
import { Platform } from 'react-native'

export default class Api {
  accessToken: string
  apiBase: string
  password: string
  sessionToken: string
  standardTimeout: number
  urlBase: string
  username: string
  jobid: string
  ClientSecret: string

  constructor () {
    this.urlBase = 'https://justonejourney.aktiv8change.co.uk/api/'
    this.apiBase = this.urlBase + 'api/'
    this.username = ''
    this.password = ''
    this.standardTimeout = 20 * 1000

    // OAuth token
    this.accessToken = ''
    // Customer session token
    this.sessionToken = ''
	this.jobid = ''
	this.booking_postcode = ''
	this.booking_address = ''
	this.repair_date = ''
	this.booking_key = ''
	this.submittedToShine = false
	
	this.ClientSecret = ''
	
    // Get the OAuth token on start-up
    //this.getAccessToken()


  }

  xWwwFormUrlEncoded (data: any): string {
    const components = []
    for (const property in data) {
      const encodedKey = encodeURIComponent(property)
      const encodedValue = encodeURIComponent(data[property])
      components.push(encodedKey + '=' + encodedValue)
    }
    return components.join('&')
  }

  async getAccessToken () {
    const api = this

    const data = this.xWwwFormUrlEncoded({
      grant_type: 'password',
      username: this.username,
      password: this.password
    })

    const response = await fetch(
      this.urlBase + 'token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: data
      }
    )
    const json = await response.json()
    api.accessToken = json.access_token
  }
  
  async authenticate (email, password, onDone, OnFail, notConnected) {
    const api = this
    const url = this.apiBase +
      'wusers/authenticate?email=' + encodeURIComponent(email) + 
      '&password=' + encodeURIComponent(password)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])

      if (response.status != 200) {
		  OnFail();
      } else {
		const json = await response.json()
        onDone(json)
      }
    } catch (e) {
		  notConnected()
    }
  }

  async getUserFromId (user_id, onDone, OnFail) {
    const api = this
    const url = this.apiBase +
      'wusers/getfromid?user_id=' + encodeURIComponent(user_id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])

      if (response.status != 200) {
		  OnFail();
      } else {
		const json = await response.json()
        onDone(json)
      }
    } catch (e) {
		  console.log(e)
    }
  }
  
  async deleteMe (user_id, onDone, OnFail) {
    const api = this
    const url = this.apiBase +
      'wusers/deleteme?user_id=' + encodeURIComponent(user_id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])

      if (response.status != 200) {
		  OnFail();
      } else {
        onDone()
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async addNewUser(first_name: string, last_name: string, email: string, company: string, age: string, gender: string, mobile: string, password: string, onDone, notFound) {
    const api = this
    const url = this.apiBase +
      'wusers/create?first_name=' + encodeURIComponent(first_name) +
      '&last_name=' + encodeURIComponent(last_name) +
      '&email=' + encodeURIComponent(email) +
      '&company=' + encodeURIComponent(company) +
      '&age=' + encodeURIComponent(age) +
      '&gender=' + encodeURIComponent(gender) +
      '&mobile=' + encodeURIComponent(mobile) +
      '&password=' + encodeURIComponent(password)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
		  console.log('apifail')
		  notFound()
      } else {
		const json = await response.json()
        onDone(json)
      }
    } catch (e) {
		  console.log(e)
		  notFound()
    }
  }

  async confirmEmail(guid: string, onDone) {
    const api = this
    const url = this.apiBase +
      'wusers/confirm?guid=' + encodeURIComponent(guid)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
		  console.log('apifail')
      } else {
		const json = await response.json()
        onDone(json)
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async requestPasswordReset(email: string, onDone) {
    const api = this
    const url = this.apiBase +
      'wusers/requestpwreset?email=' + encodeURIComponent(email)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
		  console.log('apifail')
      } else {
		const json = await response
        onDone(json)
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async UpdatePassword(password: string, id: string, onDone) {
    const api = this
    const url = this.apiBase +
      'wusers/resetpassword?password=' + encodeURIComponent(password) + 
      '&token=' + encodeURIComponent(id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
		  console.log('apifail')
      } else {
		const json = await response
        onDone(json)
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async updateUserBaseline(id: int, car_distance: string, bike_distance: string, walk_distance: string, public_transport_distance: string, journey_type: string, onDone, notFound) {
    const api = this
    const url = this.apiBase +
      'wusers/AddBaseline?id=' + encodeURIComponent(id) +
	  '&car_distance=' + encodeURIComponent(car_distance) +
      '&bike_distance=' + encodeURIComponent(bike_distance) +
      '&walk_distance=' + encodeURIComponent(walk_distance) +
      '&public_transport_distance=' + encodeURIComponent(public_transport_distance) +
      '&journey_type=' + encodeURIComponent(journey_type)
console.log(id)
    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
		  notFound()
		  console.log('apifail')
      } else {
        //const json = await response.json()
		onDone()
      }
    } catch (e) {
		notFound()
		  console.log(e)
    }
  }

  async submitTarget(id: int, target: string, journey_type: string, onDone) {
    const api = this
    const url = this.apiBase +
      'wusers/SetTarget?id=' + encodeURIComponent(id) +
	  '&target=' + encodeURIComponent(target) +
	  '&journey_type=' + encodeURIComponent(journey_type)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
		  onDone()
      } else {
        //const json = await response.json()
		onDone()
      }
    } catch (e) {
		  onDone()
    }
  }

  async submitPlan(user_id: int, monday: string, tuesday: string, wednesday: string, thursday: string, friday: string, saturday: string, sunday: string, onDone) {
    const api = this
    const url = this.apiBase +
      'wusers/setPlan?user_id=' + encodeURIComponent(user_id) +
      '&monday=' + encodeURIComponent(monday) +
      '&tuesday=' + encodeURIComponent(tuesday) +
      '&wednesday=' + encodeURIComponent(wednesday) +
      '&thursday=' + encodeURIComponent(thursday) +
      '&friday=' + encodeURIComponent(friday) +
      '&saturday=' + encodeURIComponent(saturday) +
      '&sunday=' + encodeURIComponent(sunday)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
		  onDone()
		  console.log('apifail')
      } else {
        const json = await response.json()
		onDone(json)
      }
    } catch (e) {
		onDone()
		  console.log(e)
    }
  }

  async getUserListWeeklyRecords(user_id: int, onDone, notFound, notConnected) {
    const api = this
    const url = this.apiBase +
      'weeklyrecord/GetUserList?user_id=' + encodeURIComponent(user_id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
	  console.log(response);
      if (response.status != 200) {
		  notFound()
      } else {
        const json = await response.json()
		onDone(json)
      }
    } catch (e) {
		  notFound()
    }
  }

  async GetUserLatestResponse(user_id: int, onDone, notFound, notConnected) {
    const api = this
    const url = this.apiBase +
      'weeklyrecord/GetUserLatestResponse?user_id=' + encodeURIComponent(user_id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
		  notFound()
      } else {
        const json = await response.json()
		onDone(json)
      }
    } catch (e) {
		console.log(e)
		  notConnected()
    }
  }

  async GetWalkSummary(user_id: int, onDone, notFound) {
    const api = this
    const url = this.apiBase +
      'weeklyrecord/GetWalkSummary?user_id=' + encodeURIComponent(user_id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
		  notFound()
      } else {
        const json = await response.json()
		onDone(json)
      }
    } catch (e) {
		  notFound()
    }
  }

  async GetUserChartData(user_id: int, onDone, notFound) {
    const api = this
    const url = this.apiBase +
      'weeklyrecord/GetUserChartData?user_id=' + encodeURIComponent(user_id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
		  notFound()
      } else {
        const json = await response.json()
		onDone(json)
      }
    } catch (e) {
		  notFound()
    }
  }

  async GetUserChartMonthlyData(user_id: int, onDone, notFound) {
    const api = this
    const url = this.apiBase +
      'weeklyrecord/GetUserChartDataByMonth?user_id=' + encodeURIComponent(user_id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
		  notFound()
      } else {
        const json = await response.json()
		onDone(json)
      }
    } catch (e) {
		  notFound()
    }
  }

  async submitWeeklyReportBaseline(user_id: int, week_beginning: string, monday: string, tuesday: string, wednesday: string, thursday: string, friday: string, saturday: string, sunday: string, journey_type: string, onDone, notFound) {
    const api = this
    const url = this.apiBase +
      'weeklyrecord/createbaseline?week_beginning=' + encodeURIComponent(week_beginning) +
	  '&user_id=' + encodeURIComponent(user_id) +
      '&monday=' + encodeURIComponent(monday) +
      '&tuesday=' + encodeURIComponent(tuesday) +
      '&wednesday=' + encodeURIComponent(wednesday) +
      '&thursday=' + encodeURIComponent(thursday) +
      '&friday=' + encodeURIComponent(friday) +
      '&saturday=' + encodeURIComponent(saturday) +
      '&sunday=' + encodeURIComponent(sunday) +
      '&journey_type=' + encodeURIComponent(journey_type)
	  
	  console.log(url)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  console.log(response.status)
      if (response.status != 200) {
		  console.log('apifail')
		  notFound()
      } else {
		  console.log('done')
		onDone()
      }
    } catch (e) {
		  console.log(e)
		  notFound()
    }
  }

  async submitTravelRecord(user_id: int, week_beginning: string, onDone, notFound) {
    const api = this
    const url = this.apiBase +
      'weeklyrecord/create?week_beginning=' + encodeURIComponent(week_beginning) +
	  '&user_id=' + encodeURIComponent(user_id) +
      '&is_baseline=N'

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  console.log(response.status)
      if (response.status != 200) {
		  console.log('apifail')
      } else {
        const json = await response.json()
		onDone(json)
     }
    } catch (e) {
		  console.log(e)
		  notFound()
    }
  }
  
  async submitTravelRecordDetails(weekly_record_id: int, travel_day: string, travelTypeId: int, travelDistance: float, onDone) {
    const api = this
    const url = this.apiBase +
      'weeklyrecord/addday?weekly_record_id=' + encodeURIComponent(weekly_record_id) +
	  '&travel_day=' + encodeURIComponent(travel_day) +
	  '&travelTypeId=' + encodeURIComponent(travelTypeId) +
	  '&travelDistance=' + encodeURIComponent(travelDistance)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  console.log(response.status)
      if (response.status != 200) {
		  console.log('apifail')
      } else {
        const json = await response.json()
		onDone(json)
     }
    } catch (e) {
		  console.log(e)
    }
  }
  

  timeoutPromise (timeout: number): Promise<Response> {
    return new Promise<Response>((resolve, reject) => setTimeout(() => reject(new Error('timeout')), timeout))
  }

  accessHeaders () {
    return {
      Authorization: 'Bearer ' + this.accessToken
    }
  }

}
