import React, { useEffect, useState } from "react";
import { View, StyleSheet, Text, Dimensions, Image, useWindowDimensions, Platform } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Button, FormGroup, FormControl } from "react-bootstrap";
import Api from '../components/Api'
import LabeledTextField from '../components/LabeledTextField'
import LabeledDropDown from '../components/LabeledDropDown'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import DefaultButton from '../components/DefaultButton'
import Heading from '../components/Heading'
import Para from '../components/Para'
import Moment from 'moment';
import Table from '../components/Table'
import { LineChart } from "react-native-chart-kit";
import AsyncStorage from '@react-native-async-storage/async-storage'
import { TabView, SceneMap, TabBar } from 'react-native-tab-view';

import styles from '../constants/Styles'
import Colors from '../constants/Colors'
import waitingimg from '../assets/loading.gif';


export default function WalkChartDistance(props) {
	const [walk_chart_data, setWalkChartData] = useState(null);
	const [walk_chart_labels, setWalkChartLabels] = useState(null);
	const [walk_chart_values, setWalkChartValues] = useState(null);

	const [walk_chart_monthly_data, setWalkChartMonthlyData] = useState(null);
	const [walk_chart_monthly_labels, setWalkChartMonthlyLabels] = useState(null);
	const [walk_chart_monthly_values, setWalkChartMonthlyValues] = useState(null);

	const [into_text, setIntoText] = useState("loading...");
	const [chart_width, setChartWidth] = useState(null);
	const [chart_heading, setChartHeading] = useState(null);
	const [chart_intro, setChartIntro] = useState(null);
	const [label_turn, setLabelTurn] = useState(-90);
	
	const layout = useWindowDimensions();
	const [index, setIndex] = React.useState(0);
	const [routes] = React.useState([
		{ key: 'weekly', title: 'Weekly' },
		{ key: 'monthly', title: 'Monthly' },
	]);

	useEffect(() => {
		AsyncStorage.getItem('user').then((value) => {
		const user = value
		const api = new Api();
		api.GetUserChartData(user, function done(data) {
			setWalkChartData(data)
			setWalkChartLabels(data.labels)
			
			if (props.type == 1)
			{
				setWalkChartValues(data.walk_distance)
				setChartHeading('Walk chart')
				setChartIntro('The chart below shows how many miles you have walked each week since you joined the scheme.')
			}
			else if (props.type == 2)
			{
				setWalkChartValues(data.co2Saving)
				setChartHeading('cO2 saving chart')
				setChartIntro('The chart below shows how many kilograms of cO2 you have saved each week since you joined the scheme. 25kg of cO2 is equivalent to one tree per year.')
			}
			if (props.type == 3)
			{
				setWalkChartValues(data.caloriesSaving)
				setChartHeading('Calorie chart')
				setChartIntro('The chart below shows how many extra calories you have burned each week since you joined the scheme.')
			}
			if (props.type == 4)
			{
				setWalkChartValues(data.moneySaving)
				setChartHeading('Money saving chart')
				setChartIntro('The chart below shows how many pounds you have saved each week since you joined the scheme.')
			}
			if (props.type == 5)
			{
				setWalkChartValues(data.cycle_distance)
				setChartHeading('Cycle chart')
				setChartIntro('The chart below shows how many miles you have cycled each week since you joined the scheme.')
			}
			
		api.GetUserChartMonthlyData(user, function done(monthlydata) {
			setWalkChartMonthlyData(monthlydata)
			setWalkChartMonthlyLabels(monthlydata.labels)
			
			if (props.type == 1)
			{
				setWalkChartMonthlyValues(monthlydata.walk_distance)
			}
			else if (props.type == 2)
			{
				setWalkChartMonthlyValues(monthlydata.co2Saving)
			}
			if (props.type == 3)
			{
				setWalkChartMonthlyValues(monthlydata.caloriesSaving)
			}
			if (props.type == 4)
			{
				setWalkChartMonthlyValues(monthlydata.moneySaving)
			}
			if (props.type == 5)
			{
				setWalkChartMonthlyValues(monthlydata.cycle_distance)
			}
			
				
		}, function notFound() {
				setChartHeading('Walk chart')
				setChartIntro('There was a problem. Please check your internet connection and try again.')

		});
				
		}, function notFound() {
				setChartHeading('Walk chart')
				setChartIntro('There was a problem. Please check your internet connection and try again.')

		});
		})
	}, [])
	
	const handleSubmit = () => {
		props.GoToScreen('summary')
	}
	const onLayout=(event)=> {
		const {x, y, height, width} = event.nativeEvent.layout;
		setChartWidth(width)
		if (Platform.OS == "web" > 300)
		{
			setLabelTurn(0)
		}
	}

	const WeeklyRoute = () => (
	<View>
		<VerticalSpace height={20} />
		<LineChart
		data={{
		  labels: walk_chart_labels,
		  datasets: [{ data: walk_chart_values }],
		}}
		width={chart_width}
		height={220}
			yAxisInterval={1} // optional, defaults to 1
			verticalLabelRotation={label_turn}
			chartConfig={{
				backgroundGradientFrom: '#fff',
				backgroundGradientFromOpacity: 0,
				backgroundGradientTo: '#fff',
				backgroundGradientToOpacity: 0,
				fillShadowGradientOpacity: 1,
			  decimalPlaces: 2, // optional, defaults to 2dp
			  color: (opacity = 1) => Colors.green,
			  labelColor: (opacity = 1) => '#000000',
			  style: {
				borderRadius: 16
			  },
			propsForVerticalLabels: {
			  translateX: 3,
			  translateY: 12,
			  height: 40,
			},
			  propsForDots: {
				r: "6",
				strokeWidth: "2",
				stroke: "#ffa726"
			  }
			}}
			bezier
			style={{
			  marginVertical: 8,
			  borderRadius: 16
			}}
		/>
		</View>
	);

const MonthlyRoute = () => (
	<View>
		<VerticalSpace height={20} />
		<LineChart
		data={{
		  labels: walk_chart_monthly_labels,
		  datasets: [{ data: walk_chart_monthly_values }],
		}}
		width={chart_width}
		height={220}
			yAxisInterval={1} // optional, defaults to 1
			verticalLabelRotation={label_turn}
			chartConfig={{
				backgroundGradientFrom: '#fff',
				backgroundGradientFromOpacity: 0,
				backgroundGradientTo: '#fff',
				backgroundGradientToOpacity: 0,
				fillShadowGradientOpacity: 1,
			  decimalPlaces: 2, // optional, defaults to 2dp
			  color: (opacity = 1) => Colors.green,
			  labelColor: (opacity = 1) => '#000000',
			  style: {
				borderRadius: 16
			  },
			  propsForDots: {
				r: "6",
				strokeWidth: "2",
				stroke: "#ffa726"
			  }
			}}
			bezier
			style={{
			  marginVertical: 8,
			  borderRadius: 16
			}}
		/>
		</View>
);

const renderScene = SceneMap({
  weekly: WeeklyRoute,
  monthly: MonthlyRoute,
});

const renderTabBar = props => (
  <TabBar
    	{...props}
    	activeColor={Colors.white}
    	inactiveColor={Colors.white}
        style={{backgroundColor:Colors.green}}
  />
);

  return (
  		<KeyboardAwareScrollView style={styles.outerContainer}>
		  <View style={styles.innerContainer}>
			<NavigationBar logout={props.logout} goHome={props.goHome} user={props.user} ></NavigationBar>
		  </View>
		  <View style={styles.container, styles.greenStripe}>
			<View style={[styles.innerContainer, styles.container]}>
				<Heading fontsize={34} >{chart_heading}</Heading>
			</View>
		  </View>
		  <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
			<VerticalSpace height={20} />
			<Para>{chart_intro}</Para>
			<View onLayout={onLayout}>
				<VerticalSpace height={20} />
					{walk_chart_labels == null || walk_chart_values == null || walk_chart_monthly_labels == null || walk_chart_monthly_values == null ? <View style={styles.imageWrapper} >
					<Image style={styles.waitingimgstyle} alt="Waiting..." source={waitingimg}></Image>
				</View>
 :
			<View style={{height: 400}}>
			 <TabView
				  navigationState={{ index, routes }}
				  renderScene={renderScene}
				  onIndexChange={setIndex}
				  initialLayout={{ width: layout.width }}
				  renderTabBar={renderTabBar}
				  height={350}
				/>
				</View>
					}
			  <VerticalSpace height={20} />
				<DefaultButton onPress={handleSubmit} text={"Done"}/>
				<VerticalSpace height={20} />
			</View>
		  </View>
		  </View>
		  <FooterBar />
		</KeyboardAwareScrollView>


  );
}

