import React, { useState } from "react";
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Button, FormGroup, FormControl } from "react-bootstrap";
import Api from '../components/Api'
import LabeledTextField from '../components/LabeledTextField'
import LabeledDropDown from '../components/LabeledDropDown'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import ConditionalButton from '../components/ConditionalButton'
import DefaultButton from '../components/DefaultButton'
import Heading from '../components/Heading'
import Para from '../components/Para'
import AsyncStorage from '@react-native-async-storage/async-storage'

import styles from '../constants/Styles'
import Colors from '../constants/Colors'

export default function SetLastWeekLunch(props) {
  const [monday, setMonday] = useState("");
  const [tuesday, setTuesday] = useState("");
  const [wednesday, setWednesday] = useState("");
  const [thursday, setThursday] = useState("");
  const [friday, setFriday] = useState("");
  const [saturday, setSaturday] = useState("");
  const [sunday, setSunday] = useState("");
  const [an_error, setError] = useState(false);

 const updateMonday = (text: string) => {
 	  setMonday(text == null ? '' : text)
  }
  
 const updateTuesday = (text: string) => {
 	  setTuesday(text == null ? '' : text)
  }
  
 const updateWednesday = (text: string) => {
 	  setWednesday(text == null ? '' : text)
  }
  
 const updateThursday = (text: string) => {
 	  setThursday(text == null ? '' : text)
  }
  
 const updateFriday = (text: string) => {
 	  setFriday(text == null ? '' : text)
  }
  
 const updateSaturday = (text: string) => {
 	  setSaturday(text == null ? '' : text)
  }
  
 const updateSunday = (text: string) => {
 	  setSunday(text == null ? '' : text)
  }

  const transportItems = [
                { label: 'Car', value: '1' },
                { label: 'Cycle', value: '2' },
                { label: 'Walk', value: '3' },
                { label: 'Public transport', value: '4' },
                { label: 'Other', value: '5' },
                { label: 'I didnt go out to lunch', value: '6' },
                { label: 'I didnt work that day', value: '7' },
            ]
  
  const handleSubmit = (event) => {
    event.preventDefault();
	AsyncStorage.getItem('reguser').then((value) => {
    	const user = value

		const api = new Api();
		api.submitWeeklyReportBaseline(user, "2000-01-01 00:00:00.000", monday, tuesday, wednesday, thursday, friday, saturday, sunday, 'lunch', function done() {
				props.onBaselineSet()
				
		}, function notFound() {
			// not found
			//this.state.error = "Error";
			setError()
			console.log('not found');
		});
	})
  }
  
  const handleBack = () => {
	  props.GoBack();
  }

  const validateForm = () => {
    return monday.length > 0 && tuesday.length > 0 && wednesday.length > 0 && thursday.length > 0 && friday.length > 0 && saturday.length > 0 && sunday.length > 0;
  }
  

  return (
  		<KeyboardAwareScrollView style={styles.outerContainer}>
		  <View style={styles.innerContainer}>
			<NavigationBar logout={props.logout} goHome={props.goHome} user={props.user} ></NavigationBar>
		  </View>
		  <View style={styles.container, styles.greenStripe}>
			<View style={[styles.innerContainer, styles.container]}>
				<Heading fontsize={34} >Register</Heading>
			</View>
		  </View>
		  <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
				<VerticalSpace height={20} />
					<Para>How do you normally travel to lunch?  Please base your replies on your last working week.</Para>
						<LabeledDropDown items={transportItems} placeholder="Required" label="Monday" onChange={updateMonday} value={monday} isValid={monday.length > 0}/>
						<LabeledDropDown items={transportItems} placeholder="Required" label="Tuesday" onChange={updateTuesday} value={tuesday} isValid={tuesday.length > 0} />
						<LabeledDropDown items={transportItems} placeholder="Required" label="Wednesday" onChange={updateWednesday} value={wednesday} isValid={wednesday.length > 0} />
						<LabeledDropDown items={transportItems} placeholder="Required" label="Thursday" onChange={updateThursday} value={thursday} isValid={thursday.length > 0} />
						<LabeledDropDown items={transportItems} placeholder="Required" label="Friday" onChange={updateFriday} value={friday} isValid={friday.length > 0} />
						<LabeledDropDown items={transportItems} placeholder="Required" label="Saturday" onChange={updateSaturday} value={saturday} isValid={saturday.length > 0} />
						<LabeledDropDown items={transportItems} placeholder="Required" label="Sunday" onChange={updateSunday} value={sunday} isValid={sunday.length > 0} />
					<VerticalSpace height={40} />
						{ an_error == false ? null :
							<Para>There is a problem. Please check your internet connection and try again.</Para>
						}
					<ConditionalButton onPress={handleSubmit} condition={validateForm} text="Submit"/>
					<VerticalSpace height={20} />
					<DefaultButton onPress={handleBack} text={'Back'}/>
				<VerticalSpace height={20} />
			</View>
		  </View>
		  <FooterBar />
		</KeyboardAwareScrollView>


  );
}
