import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Button, FormGroup, FormControl } from "react-bootstrap";
import Api from '../components/Api'
import LabeledTextField from '../components/LabeledTextField'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import ConditionalButton from '../components/ConditionalButton'
import DefaultButton from '../components/DefaultButton'
import Heading from '../components/Heading'
import Para from '../components/Para'
import Modal from "react-native-modal";
import AsyncStorage from '@react-native-async-storage/async-storage'

import styles from '../constants/Styles'
import Colors from '../constants/Colors'

export default function Ready(props) {
	const [costSaving, SetCostSaving] = useState(null)
	const [cO2Saving, SetcO2Saving] = useState(null)
	const [cycleCalories, SetCycleCalories] = useState(null)
	const [walkCalories, SetWalkCalories] = useState(null)
	const [walkNumDays, SetWalkNumDays] = useState(null)
	const [walkDays, SetWalkDays] = useState(null)

	const [costSavingLunch, SetCostSavingLunch] = useState(null)
	const [cO2SavingLunch, SetcO2SavingLunch] = useState(null)
	const [cycleCaloriesLunch, SetCycleCaloriesLunch] = useState(null)
	const [walkCaloriesLunch, SetWalkCaloriesLunch] = useState(null)
	const [walkDistanceLunch, SetWalkDistanceLunch] = useState(null)
	const [walkNumDaysLunch, SetWalkNumDaysLunch] = useState(null)
	const [walkDaysLunch, SetWalkDaysLunch] = useState(null)

	const [isModalVisible, setIsModalVisible] = useState(false);

	useEffect(() => {
		AsyncStorage.getItem('carDistance').then((value) => {
			SetcO2Saving(value)
		})
		AsyncStorage.getItem('costSaving').then((value) => {
			SetCostSaving(value)
		})
		AsyncStorage.getItem('cycleCalories').then((value) => {
			SetCycleCalories(value)
		})
		AsyncStorage.getItem('walkCalories').then((value) => {
			SetWalkCalories(value)
		})
		AsyncStorage.getItem('walkNumDays').then((value) => {
			SetWalkNumDays(value)
		})
		AsyncStorage.getItem('walkDays').then((value) => {
			SetWalkDays(value)
		})
		AsyncStorage.getItem('carDistanceLunch').then((value) => {
			SetcO2SavingLunch(value)
		})
		AsyncStorage.getItem('costSavingLunch').then((value) => {
			SetCostSavingLunch(value)
		})
		AsyncStorage.getItem('cycleCaloriesLunch').then((value) => {
			SetCycleCaloriesLunch(value)
		})
		AsyncStorage.getItem('walkCaloriesLunch').then((value) => {
			SetWalkCaloriesLunch(value)
		})
		AsyncStorage.getItem('walkDistanceLunch').then((value) => {
			SetWalkDistanceLunch(value)
		})
		AsyncStorage.getItem('walkNumDaysLunch').then((value) => {
			SetWalkNumDaysLunch(value)
		})
		AsyncStorage.getItem('walkDaysLunch').then((value) => {
			SetWalkDaysLunch(value)
		})

	}, [])
	
	const handleModal = () => setIsModalVisible(() => !isModalVisible);
	
function removeLastComma(strng){        
    var n=strng.lastIndexOf(",");
    var a=strng.substring(0,n) 
    return a;
}

function replaceLastCommaWithAnd(strng){        
    strng = strng.replace(/,(?![\s\S]*,)/, ' and');

    return strng;
}

  const handleSubmit = (event) => {
    event.preventDefault();
	
}
  
  return (
  		<KeyboardAwareScrollView style={styles.outerContainer}>
		  <View style={styles.innerContainer}>
			<NavigationBar logout={props.logout} goHome={props.goHome} user={props.user} ></NavigationBar>
		  </View>
		  <View style={styles.container, styles.greenStripe}>
			<View style={[styles.innerContainer, styles.container]}>
				<Heading fontsize={34} >Register</Heading>
			</View>
		  </View>
		  <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
				<VerticalSpace height={20} />
					<Para bold={true}>Well done – you have set a personal goal.</Para>
					<Para>We have sent you an email, please click the link in the email to activate your account. If you can’t find the email, check your spam box.</Para>
					<Para>Your benefits and plan to achieve them are:</Para>
					<Para>You have set yourself the goal of walking to work {walkNumDays} days per week. Your ideal day(s) are {walkDays}</Para>
					<Para>The benefits over a year would be:</Para>
						<Para>Financial saving = <Text fontWeight={'bold'}>&pound;{costSaving}</Text></Para>
						<Para>CO2 saving = <Text fontWeight={'bold'}>{cO2Saving} kg</Text></Para>
						<Para>Calories burnt = <Text fontWeight={'bold'}>{walkCalories}</Text></Para>
						
						
				{walkDistanceLunch == 0 ? null : 	
					<View><Para>You have also set yourself the goal of walking to lunch {walkNumDaysLunch} days per week.  Your ideal day(s) are {walkDaysLunch}</Para>
					<Para>The benefits over a year would be:</Para>
						<Para>Financial saving = <Text fontWeight={'bold'}>&pound;{costSavingLunch}</Text></Para>
						<Para>CO2 saving = <Text fontWeight={'bold'}>{cO2SavingLunch} kg</Text></Para>
						<Para>Calories burnt = <Text fontWeight={'bold'}>{walkCaloriesLunch}</Text></Para>
					</View>
				}	
						
					<Para>Come back next week to update yourself on how you are doing.  We will send you a reminder in the next day or so.</Para>
					<Para bold={true}>Remember to click on the link we have sent you to activate your account.</Para>
					<VerticalSpace height={40} />
				<VerticalSpace height={20} />
					  <Modal isVisible={isModalVisible} >
						<View  style={styles.modal}>
							<Para>These are estimates based on typical values, and assuming you travel to work 47 weeks per year. CO2 produced is dependent on: </Para>
							<Text>{'\u2022'} Type of vehicle</Text>
							<Text>{'\u2022'} Driving style</Text>
							<Text>{'\u2022'} Journey type</Text>
							<Para>Calories burned is dependent on:</Para>
							<Text>{'\u2022'} Gender</Text>
							<Text>{'\u2022'} Weight</Text>
							<Text>{'\u2022'} Pace</Text>
							<Para>Fuel costs are on average 22.5p per mile, but vary dependent upon vehicle, engine type (diesel/petrol) and style of driving.
							</Para>
							<VerticalSpace height={20} />
						  <DefaultButton text="Close" onPress={handleModal} />
						  </View>
					  </Modal>
			</View>
		  </View>
		  <FooterBar />
		</KeyboardAwareScrollView>


  );
}
