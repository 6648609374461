import React from 'react'
import { StyleSheet, View, Platform } from 'react-native'
import {Picker} from '@react-native-picker/picker';

import Para from './Para'

type LabeledDropDownProps = {
  label: string,
  onChange: (text: string) => void,
  value: string
  items: Any
  password?: string
  isValid?: string
}

const LabeledDropDown = ({ label, onChange, placeholder = '', value, password = false, items, isValid = true}: LabeledTextFieldProps) => {
  return (
    <View>
      <Para>{label}</Para>
		  { isValid ? 
		  <View style={styles.pickerwrapper}>
			<Picker
			  style={styles.pickerbox}
			  value={value}
			  selectedValue={value}
			  items={items}
				onValueChange={(itemValue, itemIndex) => onChange(itemValue)} >
			  <Picker.Item label={'Select an item'} value={''} style={styles.pickerItem} />
				{items.map(d => {
				  return (<Picker.Item label={d.label} value={d.value} style={styles.pickerItem} />);
				})}
				
			  </Picker>
			</View>
				:
		  <View style={styles.pickerwrapperinvalid}>
			<Picker
			  style={styles.pickerbox}
			  value={value}
			  selectedValue={value}
			  items={items}
				onValueChange={(itemValue, itemIndex) => onChange(itemValue)} >
			  <Picker.Item label={'Select an item'} value={''} style={styles.pickerItem} />
				{items.map(d => {
				  return (<Picker.Item label={d.label} value={d.value} style={styles.pickerItem} />);
				})}
				
			  </Picker>
			</View>
		  }
    </View>
  )
}

export default LabeledDropDown

const pickerstyle = StyleSheet.create({
	border: 'none',
}
)

const pickerstyleInvalid = StyleSheet.create({
	border: 'none',
}
)

const styles = {
	tooltipIconSmall: {
		height: 20,
		width: 20,
	},
	pickerwrapper: {
		borderWidth: 1,
		backgroundColor: '#ffffff',
		borderColor: '#000000',
	},
	pickerbox: {
		fontSize: 16,
		borderWidth: 0,
		...Platform.select({
		  web: {
			height: 55
		  },
		  android: {
			height: 55
		  },
		}),
	},
	pickerwrapperinvalid: {
		borderWidth: 3,
		backgroundColor: '#ffffff',
		borderColor: '#ff0000',
	},
	pickerItem: {
		...Platform.select({
		  web: {
			height: 55
		  },
		  android: {
			height: 55
		  },
		}),
	}
}

