import React, { useState } from 'react'
import { StyleSheet, View, Image, Text, TouchableOpacity } from 'react-native'
import { TextInput } from 'react-native-gesture-handler'
import Para from './Para'

import VerticalSpace from '../components/VerticalSpace'
import DefaultButton from '../components/DefaultButton'
import Modal from "react-native-modal";
import Styles from '../constants/Styles'


type LabeledTextFieldWithTooltipProps = {
  label: string,
  onChange: (text: string) => void,
  placeholder?: string
  value: string
  password?: string
  tooltip: string
  tooltipid: string
  isValid?: string
}

const LabeledTextFieldWithTooltip = ({ label, onChange, placeholder = '', value, password = false, tooltip='', tooltipid='', isValid = true}: LabeledTextFieldWithTooltipProps) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const handleModal = () => setIsModalVisible(() => !isModalVisible);

  return (
    <View>
      <Para>{label} <TouchableOpacity onPress={handleModal}><Image style={styles.tooltipIconSmall} data-tip={tooltipid} data-for={tooltipid} source={require('../assets/questionmark.png')} ></Image></TouchableOpacity></Para>
		  { isValid ? 
      <TextInput value={value} placeholder={placeholder} secureTextEntry={password} style={styles.textInput} onChangeText={onChange}/> :
      <TextInput value={value} placeholder={placeholder} secureTextEntry={password} style={styles.textInputInvalid} onChangeText={onChange}/>
		  }
	  <Modal isVisible={isModalVisible} >
		<View style={Styles.modal}>
			<Para>{tooltip}</Para>
			<VerticalSpace height={20} />
		  <DefaultButton text="Close" onPress={handleModal} />
		  </View>
	  </Modal>
    </View>


  )
}

export default LabeledTextFieldWithTooltip

const styles = StyleSheet.create({
  textInput: {
    borderWidth: 1,
    fontSize: 16,
    height: 55,
    paddingHorizontal: 4,
	backgroundColor: '#ffffff',
  },
  textInputInvalid: {
    borderWidth: 3,
    fontSize: 16,
    height: 55,
    paddingHorizontal: 4,
	borderColor: 'red',
	backgroundColor: '#ffffff',
  },
	tooltipIconSmall: {
		height: 20,
		width: 20,
	}
  
})

