import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, Platform } from 'react-native';
import Privacy from './screens/Privacy'
import Home from './screens/Home'
import Terms from './screens/Terms'
import Confirm from './screens/Confirm'
import ConfirmPassword from './screens/ConfirmPassword'
import PasswordConfirmed from './screens/PasswordConfirmed'
import FAQ from './screens/FAQ'

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

import Api from './components/Api'

export default function App() {

  if (Platform.OS === 'web'){
	return (
	  <Router>
			<Routes>
			  <Route exact path="/" element={<Home />} />
			  <Route path="/privacy" element={<Privacy />} />
			  <Route path="/terms" element={<Terms />} />
			  <Route path="/confirm" element={<Confirm />} />
			  <Route path="/confirmpassword" element={<ConfirmPassword />} />
			  <Route path="/passwordconfirmed" element={<PasswordConfirmed />} />
			  <Route path="/faq" element={<FAQ />} />
			</Routes>
	  </Router>
	  )
  }
  else {
  return (
  <Home />
  )
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
