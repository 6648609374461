import React, { useState } from "react";
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Button, FormGroup, FormControl } from "react-bootstrap";
import Api from '../components/Api'
import LabeledTextField from '../components/LabeledTextField'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import ConditionalButton from '../components/ConditionalButton'
import Heading from '../components/Heading'
import Para from '../components/Para'

import styles from '../constants/Styles'
import Colors from '../constants/Colors'

export default function ResetPassword(props) {
  const [submitted, setSubmitted] = useState(false);
  const [submit_button_text, setSubmitButtonText] = useState("Submit");
  const [email, setEmail] = useState('');
  
  const handleSubmit = (event) => {
    event.preventDefault();
	setSubmitted(true);
	setSubmitButtonText('Please wait')
	
	const api = new Api();
	api.requestPasswordReset(email, function done(data) {
			props.requestSucceeded()
			
	}, function notFound() {
		// not found
		//this.state.error = "Error";
		setSubmitted(false);
		setSubmitButtonText('Submit')
	});
  }
  const validateForm = () => {
    return email.length > 0 && ValidateEmail(email) == true && !submitted;
  }

  function ValidateEmail(mail) 
	{
	 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
	  {
		return (true)
	  }
		return (false)
	}
  
  return (
  		<KeyboardAwareScrollView style={styles.outerContainer}>
		  <View style={styles.innerContainer}>
			<NavigationBar logout={props.logout} goHome={props.goHome} user={props.user} ></NavigationBar>
		  </View>
		  <View style={styles.container, styles.greenStripe}>
			<View style={[styles.innerContainer, styles.container]}>
				<Heading fontsize={34} >Forgot your password?</Heading>
			</View>
		  </View>
		  <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
				<VerticalSpace height={20} />
					<Para>To reset your password, enter your email address below and we'll send you an email with instructions on how to create a new password.</Para>
					<VerticalSpace height={20} />
					<LabeledTextField placeholder="Required" label="Email" onChange={setEmail} value={email}/>
					<VerticalSpace height={40} />
					<ConditionalButton onPress={handleSubmit} condition={validateForm} text={submit_button_text}/>
				<VerticalSpace height={20} />
			</View>
		  </View>
		  <FooterBar />
		</KeyboardAwareScrollView>


  );
}
